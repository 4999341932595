/*/ Add search bar icon in menu mobile top /*/
function changeLocale(locale, uri, redirect = true) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri, 
            redirect: redirect
        },
        success: function (data) {
            if (data != '') {
                var force = '';
                if (uri == '/rodier.fr') {
                    force = '?force';
                }
                if (redirect) {
                    location.href = data + force;
                } else {
                    urlLang = data;
                }
            }
        }
    });
}

function showhide_search() {
    // If menu is open we close the menu to prevent overlay
    if ($('#main_menu_btn').hasClass('menu_open')) {
        toggleMenu('left', 'left');
    }

    if ($('#top_searchV2').hasClass('activated')) {
        // Close
        $('#top_searchV2').removeClass('activated').css('display','none');
        $("#shade").removeClass('search visible');
        $("body").addClass('search_hidden').css('overflow','auto');
        $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 30);
    } else {
        // Open
        $('#top_searchV2').addClass('activated').css('display','block');
        $("body").removeClass('search_hidden');
        $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 105);
        $(".banner_wrapper").css('overflow', 'unset');
        $('#shade').addClass('search visible');
    }

    if ($(".dynasearchwrapper").hasClass("close_search")) {
        $(".dynasearchwrapper").removeClass("close_search");
    }

    if ($("header #top_searchV2").length < 1) {
        $("#top_searchV2").appendTo("header.banner_wrapper");
    }

    $('#search_homeV2').focus();
}

/* Search ajax */
function otherJsFunction() {
    if (!$('#top_searchV2[style="display: block;"]')) {
        $(".dynasearchwrapper").addClass("close_search");
    }
}

$(document).ready(function () {
    otherJsFunction();

    /***** Warning login sentence to inform customer *****/
    $('<p class="subtitle warning">' + Translator.translate('existing_user_pwd_reset') + '</p>').insertAfter('#subtitleLogin');

    setTimeout( () => {
        /* Add class has_bando if bandeauMenuAffix is present */
        if ($(".bandeauMenuAffix").length > 0) {
            $("body").addClass("has_bando");
        }

        //sliderBandos top swiper mobile
        if ($(".banner_wrapper #sliderBandos .swiper-slide").length > 1) {
            var bandoHeaders = new Swiper(".banner_wrapper #sliderBandos", {
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                direction: "vertical",
                autoplay: 3000,
                speed: 2200,
                autoplayDisableOnInteraction: false
            });
        }
    }, 300 );

    setTimeout(function(){
        $('#shade, #general_shade, #form_recherche .close_search, .btn_close').click(function(){
            if ($('#top_searchV2').hasClass('activated')){
                $('#top_searchV2').css('display','none');
                $("#shade").removeClass('visible');
                $("body").addClass('search_hidden');
                $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 30);
            }
        });
    },500);

    /***** CMS PAGE NAV SWIPER *****/

    /** Add swiper classes to block */
    var cmsNavSwiper = $('body.cms_page .cms_category_1 .nav_holder');

    cmsNavSwiper.addClass('swiper-container');

    $('.satellite_nav_bar', cmsNavSwiper).addClass('swiper-wrapper');

    $('.tabsatellite', cmsNavSwiper).each(function() {
        $(this).addClass('swiper-slide');
    });

    // Add swiper classes to block
    if ($('body.cms_page_new').length) {
        var cmsNewNavSwiper = $('body.cms_page_new .cms_category_1 .nav_holder');

        cmsNewNavSwiper.addClass('swiper-container');
        $('.satellite_nav_bar', cmsNewNavSwiper).addClass('swiper-wrapper');

        $('.tabsatellite', cmsNewNavSwiper).each(function() {
            $(this).addClass('swiper-slide');
        });
    }

    /** Add buttons */
    $("<div class='swiper-cmsNav-button swiper-cmsNav-button-next'></div>").insertAfter(cmsNavSwiper);
    $("<div class='swiper-cmsNav-button swiper-cmsNav-button-prev'></div>").insertAfter(cmsNavSwiper);

    /** Move title */
    $('.page_upper_title', cmsNavSwiper).insertBefore(cmsNavSwiper);

    /***** CMS PAGE QUESTIONS SWIPER *****/

    var cmsSwiper = $('body.cms_page .full_slider');

    /** Add buttons */
    $("<div class='swiper-cms-button swiper-cms-button-next'></div>").insertBefore(cmsSwiper);
    $("<div class='swiper-cms-button swiper-cms-button-prev'></div>").insertBefore(cmsSwiper);

    /***** CMS PAGE IMAGE-IMAGE-IMAGE SWIPER *****/

    /** Add swiper classes to block */
    var cmsImagesSwiper = $('body.cms_page .home-module.image-image-image .home-columns-container');

    cmsImagesSwiper.addClass('swiper-container');

    $('.insideImg', cmsImagesSwiper).addClass('swiper-wrapper');

    $('article.home-column', cmsImagesSwiper).each(function() {
        $(this).addClass('swiper-slide');
    });

    /*******************************************/
    /**** MOBILE toggleNumObjects **************/
    $('div.toggleNumObjects span').click(function () {

        if ($(this).attr('id') == 'oneProduct') {
            $('.item_container').addClass('full');
            $('.item.push').addClass('full');
            createCookie('nb_produits', 1);
        } else {
            $("#push_rayon_1").css('display', 'none');
            $('.item_container').removeClass('full');
            $('.item.push').removeClass('full');
            createCookie('nb_produits', 2);
        }

        $('div.toggleNumObjects span').removeClass('actif');
        $(this).addClass('actif');

    });

    /* Navigation Page rayon sub_categ_swiper */
    if ($("#sub_categ_swiper .swiper-slide").length > 1) {
        var subCategSwiper = new Swiper("#sub_categ_swiper", {
            slidesPerView: "auto",
            spaceBetween: 15,
            freeMode: true,
        });
    }

    if ($(".image-image-image.swiper-container .swiper-slide").length > 2) {
        var threeImagesSlider = new Swiper(".image-image-image.swiper-container", {
            slidesPerView: 2,
            spaceBetween: 7,
        });
    }

    // Added to avoid overriding Vue.js component from _APP (WP-29253)
    if ($('body.cart').length && $('#formPromo').length) {
        $('#formPromo .w-submit-button:not(.w-loader) span').html(Translator.translate('validate')).addClass('show');
    }

    $('#search_homeV2').on('input', function() {
        if ($(this).val().length > 2) {
            $('#shade').removeClass('search visible');
        } else {
            $('#shade').addClass('search visible');
        }
    });

    setTimeout(() => {
        /* Add a video to the Tunnel-Bank thank you page  */
        if ($("body").hasClass("bankThanks")) {
            const videoPath = 'img/video_remerciements.mp4';
            const videoURL = path_relative_root + videoPath;
            const bankThanksTop = document.querySelector('.bankThanksTop');
            const videoRemerciements = Object.assign(document.createElement('div'), { id: 'videoRemerciements' });
            const videoElement = document.createElement('video');

            videoElement.setAttribute('src', videoURL);
            videoElement.removeAttribute('controls');
            videoElement.setAttribute('autoplay', 'autoplay');
            videoElement.setAttribute('loop', 'true');
            videoElement.setAttribute('muted', '');
            videoElement.setAttribute('playsinline', 'playsinline');
            videoElement.style.width = '100%';
            videoRemerciements.appendChild(videoElement);
            bankThanksTop.appendChild(videoRemerciements);
            videoElement.muted = true;
        }

        if ($('.footerCats .triggerCofidisLightbox').length) {
            $('.footerCats .triggerCofidisLightbox').on('click', function(e) {
                // Prevent any redirection if a link to a page is present
                e.preventDefault();
            });
        }

        $(".image-image-image").each(function () {
            var threeImagesSliderAdv = $(this).find(".swiper-container");

            if (threeImagesSliderAdv.find(".swiper-slide").length > 2) {
                new Swiper(threeImagesSliderAdv[0], {
                    slidesPerView: 1.85,
                    spaceBetween: 7,
                });
            }
        });

    }, 500);

    // TUNNEL V3 FRONT

    // tunnel V3 size modal toggle
    $('body').on('click', '.toggle_modal_actions', function () {
        $(this).parents('.product_line_basket').find('.cart_item_actions').addClass('actif');
        if (!$(this).parents('.product_line_basket').find('.cart_item_actions .titre_choices_list').length) {
            var modal_cart_item_actions = $(this).parents('.product_line_basket').find('.cart_item_actions');
            var modal_cart_size_title = $("<div class='titre_choices_list'>" + Translator.translate('modify_size') + "</div>");

            modal_cart_size_title.prependTo(modal_cart_item_actions);
        }

        if (!$('#shade').hasClass('visible')) {
            $('#shade').addClass('visible');
        }
    });

    // tunnel v3 delivery step move delivery choice below address recap
    if($('.cart.step_2 .left_side.elem_tunnel').length) {
        $('.cart.step_2 .left_side.elem_tunnel').appendTo('.right_side.elem_tunnel');
    }

    // tunnel v3 cart step elements movements reloadLeftAndRightTunnel_success wishToBasket_success wishToBasket_complete function
    cartLoadMovement();

    $(window).on("reloadLeftAndRightTunnel_success wishToBasket_success wishToBasket_complete", function () {
        cartLoadMovement();
    });
    
    // Listen to the wishToBasket_complete event to update the wishlist
    $(window).on("wishToBasket_success", function () {
        updateCartElements();
    });
    
    // Listen to the wishToBasket_complete event to update the wishlist
    $(window).on("wishToBasket_complete", function () {
        updateCartElements();
    });

    // Listen to the removeBasketItemCustom event to update the cart
    $(window).on("removeBasketItemCustom", function (data) {
        remove_shad();
    });

    // Managing toggle visibility for forget password page
    if ($('body').hasClass('pwdforget') || $('body').hasClass('script_renderpwdforget') || $('body').hasClass('customer') || $('body').hasClass('cart')) {

        // End document.ready
        setTimeout(function() {
            const inputs = $('#clientPwd, #clientPwdv, #clientPwdw'); // Select both password inputs

            // Function to toggle the class "w-nonempty" based on input value
            function toggleNonEmptyClass() {
                inputs.each(function () {
                    if ($(this).val().length > 0) {
                        $(this).parent().addClass('w-nonempty');
                    } else {
                        $(this).parent().removeClass('w-nonempty');
                    }
                });
            }

            // Check on page load
            toggleNonEmptyClass();

            // Check when typing or deleting
            inputs.on('input', toggleNonEmptyClass);
        }, 500);
    }

    // Function to check all paragraph tags and add class if empty
    function checkEmptyParagraphs() {
        $('p').each(function() {
            if ($(this).html().trim() === '') {
                $(this).addClass('empty');
            }
        });
    }

    // Call the function to check paragraphs
    if ($('body').hasClass('order_cgv')) {
        checkEmptyParagraphs();
    }
});

function close_search() {
    $('#top_searchV2').removeClass('activated');
    $('#top_searchV2').hide();
    $('#shade').removeClass('search visible');
    $('body').removeAttr('style');

    if ($("body .dynasearchwrapper").length > 0) {
        $(".dynasearchwrapper").addClass("close_search");
    }
}

function toggleViewMobile() {
    /**** MOBILE toggleNumObjects **************/
    $('div.toggleNumObjects span').on("click tap touch", function() {
        if ($(this).attr('id') == 'oneProduct') {
            $('.item_container').addClass('full');
            $('.item.push').addClass('full');
            createCookie('nb_produits', 1);
        } else {
            $("#push_rayon_1").css('display', 'none');
            $('.item_container').removeClass('full');
            $('.item.push').removeClass('full');
            createCookie('nb_produits', 2);
        }

        $('div.toggleNumObjects span').removeClass('actif');
        $(this).addClass('actif');
    });
}

function closeAlerteStockWishlist(elem) {
    $('.modal_shade').click();
}

/*
    Taken from wshop-front\_app\_src\js\vue-app-mobile.js
    To init special slider for categories (ex : Jonak)
*/
function searchCategoryFilter() {
    /* Navigation Page rayon sub_categ_swiper */
    if ($("#sub_categ_swiper .swiper-slide").length > 1) {
        var subCategSwiper = new Swiper("#sub_categ_swiper", {
            slidesPerView: "auto",
            spaceBetween: 15,
            freeMode: true,
        });
    }

    /* Debug after search */
    setTimeout(() => {
        /* For the search page, the default view button shows two products */
        $('div.toggleNumObjects #twoProducts').click();
        $('div.toggleNumObjects #twoProducts').click();
    }, 100);
}

if (readCookie('nb_produits') == 1){
    $('div.item_container').addClass('full');
    $('.list_item .item.push').addClass('full');
    $('div.toggleNumObjects span').removeClass('actif');
    $("#oneProduct").addClass('actif');
}

// Toggles side menu of given id comming from given side
function toggleMenu(id) {
    var menu = document.getElementById(id + '_menu');
    var shad = document.getElementById('shade');
    var iconMenu = document.getElementById('main_menu_btn');

    if (menu) {
        if (menu.classList.toggle('open') && shad) {
            shad.classList.add('visible');
            shad.classList.add('forMenu');

            if (id == "left") {
                iconMenu.classList.add("menu_open");
                $("body").addClass("menu_left_open");
            }
        } else if (shad) {
            shad.classList.remove('visible');
            shad.classList.remove('forMenu');
            document.body.classList.remove("noscroll");

            if (id == "left") {
                iconMenu.classList.remove("menu_open");
                $("body").removeClass("menu_left_open");
            }
        }
    } else if (shad) {
        shad.classList.toggle('visible');

    } if ( id === "filters") {
        shad.classList.toggle("forFilters");
    }

    $("#top_searchV2").removeClass("activated").hide();
    $("header.banner_wrapper").removeAttr("style");

    setTimeout(() => {
        //sliderBandos top swiper mobile
        if ($("#left_menu #sliderBandos .swiper-slide").length > 1) {
            var bandoHeaderss = new Swiper("#left_menu #sliderBandos", {
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                direction: "vertical",
                autoplay: 3000,
                speed: 2200,
                autoplayDisableOnInteraction: false
            });
        }

        $(".dynasearchwrapper").addClass("close_search");
    }, 250);
}

// FAQ
function faqinit(array_questions) {

    $(document).ready(loadAnchor());

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each( array_questions, function( i, val ) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if ( match ) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            //$('#faq_search').val('');
        }
    });

    $('#theme_global .theme').on('click', function() {
        location.hash = $(this).attr('data-theme');
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });

    // When anchor is added to URL, open the relevant FAQ section
    if ("onhashchange" in window) {
        window.onhashchange = function () {
            loadAnchor();
        }
    }

}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $('.faq_reponse:not(.faq_reponse_' + question_id + ')').slideUp('fast', function() {

            $(this).addClass('cache');
        });

        $('.faq_reponse_' + question_id).slideToggle('slow', function() {

            $(this).toggleClass('cache');
        });

        $('.faq_question:not(.faq_question_' + question_id + ')').removeClass('active');
        $('.faq_question_' + question_id).toggleClass('active');

    } else {
        var parent_id = $('.faq_theme_' + node_id ).data('parent');

        //active
        $('.faq_element:not(.faq_theme_' + node_id + ')').removeClass('active');
        $('.faq_theme_' + node_id).toggleClass('active');
        $('.faq_theme_' + parent_id).toggleClass('active');

        //update anchor
        if (Translator.translate("theme_id_" + node_id) != ("theme_id_" + node_id)) {
            location.hash = Translator.translate("theme_id_" + node_id);
        }

        //show questions
        $('.faq_questions:not(.faq_questions_' + node_id + ', .faq_questions_'+parent_id+' )').slideUp('fast', function() {

            $(this).addClass('cache');
        });

        $('.faq_questions_' + node_id).slideToggle('slow', function() {

            $(this).toggleClass('cache');
        });

        // Hide other node of same level
        $('.faq_level_' + level + ':not(.faq_theme_' + node_id + ')').each(function(idx) {
            var id = $(this).data('id');
            $('.faq_level_parent_'+ id).slideUp('fast', function() {
                $('.faq_level_parent_' + id).addClass('cache').trigger('classChange');
            });
        });

        // Show/hide child of selected node
        $('.faq_level_parent_' + node_id).slideToggle('slow', function() {

            $('.faq_level_parent_' + node_id).toggleClass('cache').trigger('classChange');
        });

        $('.faq_element').on('classChange', function(){
            var id = $(this).data('id');

            if ( $(this).hasClass('cache') ) {
                $('.faq_level_parent_' + id).addClass('cache').trigger('classChange');
            }
        });
    }
}

function moveNbr() {
    var artSel = $('#nrArticlesSelected');
    var wishIntro = $('.selection_shopping_intro');

    artSel.appendTo(wishIntro);
}

$(function() {
    if (document.body.classList.contains('wishlist')) {
        moveNbr();
    }
});

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);

}

function loadAnchor() {
    if ($(location).attr("href").includes("faq.php#")) {
        // Fetch anchor from URL and open FAQ section
        var theme_anchor = $(location).attr("href").split("#").pop();
        var faq_theme = $(".faq_content").find(`[data-theme='${theme_anchor}']`);
        var id = $(faq_theme).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display','none');
        if (!$(".faq_theme_" + id).hasClass("active")) {
            showTabFaq(id, 1);
        }
        // If accordion is already open, delay animation so that previous tab has time to close
        const timeout = $('#theme_page').hasClass('actif') ? 200 : 0;
        setTimeout(() => {
            $("html, body").animate({scrollTop: $(".theme_detail").find(`[data-id='${id}']`).offset().top - $('.bandeauMenuAffix').height() - $('.banner_wrapper').height()}, 300);
        }, timeout);
        theme.addClass('actif');
    }
}

$( function() {

    /***** FP SWIPER(S) *****/

    /** PROD COLORS SWIPER */
    var prodSizesSwiper = new Swiper('#prodSizesSwiper', {
        slidesPerView: 6,
    });

    /** PROD SIZES SWIPER */
    var prodColorsSwiper = new Swiper('#prodColorsSwiper', {
        slidesPerView: 6,
    });

    /***** HOME SWIPER(S) *****/

    /** COVER MODULE SWIPER */
    var coverSwipers = document.querySelectorAll('#coverSwiper');
    coverSwipers.forEach(function(coverSwiper) {
        new Swiper(coverSwiper, {
            slidesPerView: 1,
            autoplay: 5000,
            speed: 500,
            loop: true,
            autoHeight: true,
            prevButton: '.swiper-cover-button-prev',
            nextButton: '.swiper-cover-button-next'
        });
    });

    /** pushProductHomeWrapper MODULE SWIPER */
    var pushProductHomeWrapper = new Swiper('#pushProductHomeWrapper', {
        slidesPerView: 1,
        nextButton: '.swiper-push-button-prev',
        prevButton: '.swiper-push-button-next',
    });

    /** IMAGE-IMAGE MODULE SWIPER */
    var superHomeSwiper = new Swiper('#super_home_swiper', {
        slidesPerView: 1,
        nextButton: '.swiper-super-button-prev',
        prevButton: '.swiper-super-button-next',
    });

    /** SLIDER MODULE SWIPER */
    var homeGondole = new Swiper('#homeGondole', {
        slidesPerView: 1.5,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true
    });

    /** 3IMAGES MODULE SWIPER */
    var threeImagesSwiper = new Swiper('#threeImagesSwiper', {
        slidesPerView: 2,
        spaceBetween: 7,
    });

    /***** RAYON SWIPER(S) *****/

     if ($('#bandeauSwiper').length) {

        var rayonInitialSlide;

        $('#bandeauSwiper .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('btn_select')) {
                rayonInitialSlide = i;
            }
        });

        var bandeauSwiper = new Swiper('#bandeauSwiper', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            nextButton: '.swiper-rayon-button-prev',
            prevButton: '.swiper-rayon-button-next',
            initialSlide: rayonInitialSlide,
        });
    }

    /***** FP SWIPER(S) *****/
    setTimeout(function() {
        if ($('.gammeSwiper .swiper-slide').length) {
            var gammeSwiper = new Swiper('.gammeSwiper', {
                slidesPerView: 2.3,
                spaceBetween: 7,
                roundLengths: true,
                pagination: '.gammeSwiper-swiper-pagination',
                paginationClickable: true,
                touchRatio: 1,
                touchMoveStopPropagation: false,
            });
        }

        if ($('#productVisitedSwiper .swiper-slide').length) {
            var productVisitedSwiper = new Swiper('#productVisitedSwiper', {
                slidesPerView: 2.3,
                spaceBetween: 7,
                roundLengths: true,
                pagination: '.visited-pagination',
                paginationClickable: true,
                touchRatio: 1,
                touchMoveStopPropagation: false,
            });
        }

        if ($('#cart_slider .swiper-slide').length > 2) {
            var likeSwiper = new Swiper('#cart_slider', {
                slidesPerView: 2.3,
                spaceBetween: 7,
                pagination: '.cart-slider-swiper-pagination',
            });
        }
    }, 500);

    /***** LOOKBOOK SWIPER *****/
    if ($('#lookbookCatSwiper').length) {

        var rayonInitialSlide = 1;

        $('#lookbookCatSwiper .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('btn_select')) {
                rayonInitialSlide = i;
            }
        });

        $('#lookbookHeadSwiper').css({'opacity' : '1'});
        var lookbookCatSwiper = new Swiper('#lookbookCatSwiper', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            initialSlide: rayonInitialSlide
        });
    }

    setTimeout(() => {
        var lookbookHeadSwiperPush = new Swiper('#lookbookHeadSwiper #pushSelection', {
            slidesPerView: 3,
            spaceBetween: 7,
            pagination: '.lookbook_head_swiper-pagination',
            paginationClickable: true,
        });
    }, 100);

    /***** ACCOUNT SWIPER(S) *****/

    /**
     * Add class .swiper-slide to .item in order to set the swiper only on mobile
     */
    var accInitialSlide = 0;

    $('#accountSwiper .swiper-slide').each(function (i, obj) {
        if ($(obj).hasClass('actif')) {
            accInitialSlide = i;
        }
    });

    setTimeout(function() {
        var accountSwiper = new Swiper('#accountSwiper', {
            slidesPerView: 'auto',
            nextButton: '.swiper-account-button-prev',
            prevButton: '.swiper-account-button-next',
            initialSlide: accInitialSlide,
            centeredSlides: false,
            centeredSlidesBounds: true,
            spaceBetween: 20,
        });

        $('#accountSwiper').css('opacity', '1');
    }, 1000);

    /***** CMS SWIPER(S) *****/

    if ($('body.cms_page .nav_holder').length) {

        var rayonInitialSlide;

        $('body.cms_page .nav_holder .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('active')) {
                rayonInitialSlide = i;
            }
        });

        setTimeout(function() {
            var cmsNavSwiper = new Swiper('body.cms_page .nav_holder', {
                slidesPerView: 3,
                slidesPerGroup: 2,
                prevButton: '.swiper-cmsNav-button-prev',
                nextButton: '.swiper-cmsNav-button-next',
                spaceBetween: 30,
                initialSlide: rayonInitialSlide
            });
        }, 100);
    }

    if ($('body.cms_page_new .nav_holder').length) {
        let rayonInitialSlide;

        $('body.cms_page_new .nav_holder .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('active')) {
                rayonInitialSlide = i;
            }
        });

        setTimeout(function() {
            let cmsNewNavSwiper = new Swiper('body.cms_page_new .nav_holder', {
                slidesPerView: 'auto',
                spaceBetween: 30,
                initialSlide: rayonInitialSlide
            });
            $('body.cms_page_new .satellite_nav_bar.primary_navbar').css('opacity', '1');
        }, 100);
    }

    var cmsImagesSwiper = new Swiper('body.cms_page .home-module.image-image-image .swiper-container', {
        slidesPerView: 1.5,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
    });

    /* BOX - PUSH SLIDER || NB : slider exists when more than 2 pushs exists */
    if ($('#pushSlider').length) {
        var pushSwiper = new Swiper('#pushSlider', {
            slidesPerView: 1.5,
            spaceBetween: 20,
            centeredSlides: true,
            initialSlide: 1
        });
    }

    $('.satellite_politique-confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.satellite_politique-confidentialite #cookiesLightbox').addClass('displayCookies');
        if ($('.satellite_politique-confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('hideCookies');
        }
        $('.satellite_politique-confidentialite #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.satellite_politique-confidentialite #js_cookie_refuse, .satellite_politique-confidentialite #js_cookie_accept').click(function() {
        if ($('.satellite_politique-confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.satellite_politique-confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.satellite_politique-confidentialite #cookiesLightbox').addClass('hideCookies');
                $('.satellite_politique-confidentialite #shadCookie').removeClass('actif');
            }, 500);
        }
    });

    /***** FOOTER ACCORDION - MOBILE ADJUSTMENTS  *****/
    /* The SEO footer has the same desktop structure; modified here with JS - Footer accordion */
    $(".site_bottom .footerCats nav ul > p").each(function() {
        $(this).on("click tap touch", function() {
            if (!$(this).hasClass("active")) {
                $(".site_bottom .footerCats nav ul > p").not(this).removeClass("active");
                $(".site_bottom .footerCats nav").removeClass("active");
                $(".site_bottom .footerCats nav ul li").slideUp(200);
                $(this).addClass("active");
                $(this).parent().find("li").slideDown(200);

            } else {
                $(this).removeClass("active");
                $(this).parent().find("li").slideUp(200);
            }
        });
    });

    // Override _app to modify Add adresse button's wording
    if ($('body.customer .customer_adress #btn_addr_add').length) {
        let newText = Translator.translate('btn_valider');
        $('#btn_addr_add span').text(newText);
    }

    // For lookbook and not edits
    if ($('body.category .in_lookbook').length && $('.container_products_edits').length < 1) {
        $('.reassurance_wrapper').css('margin-top', '0');
        $('#pagination_content').css('margin-top', '0');
    }

    if ($('#wrapper_moncompte form#clientForm').length) {
        $('#clientCivilite').on('change', function() {
            $(this).addClass('select');
        });
    }

    // move alma block
    setTimeout(() => {
        if ($('#content_product .prod_page_bot #alma-widget').length) {
            $('#alma-widget').insertAfter('#item_add_form');
        }
    }, 100);
});

$(window).on("loadProduct_success", function(event) {
    // Set an interval to check for the existence of Gamme swiper
    var checkGammeSwiperExist = setInterval(function() {
        if ($('.gammeSwiper .swiper-slide').length) {
            var gammeSwiper = new Swiper('.gammeSwiper', {
                slidesPerView: 2.3,
                spaceBetween: 7,
                roundLengths: true,
                pagination: '.gammeSwiper-swiper-pagination',
                paginationClickable: true,
                touchRatio: 1,
                touchMoveStopPropagation: false,
            });

            // Stop the interval once the element is found
            clearInterval(checkGammeSwiperExist);
        }
    }, 100); // Check every 100 milliseconds

    // Set an interval to check for the existence of Visited Product swiper
    var checkprodVisitedSwiperExist = setInterval(function() {
        if ($('#productVisitedSwiper .swiper-slide').length) {
            var productVisitedSwiper = new Swiper('#productVisitedSwiper', {
                slidesPerView: 2.3,
                spaceBetween: 7,
                roundLengths: true,
                pagination: '.visited-pagination',
                paginationClickable: true,
                touchRatio: 1,
                touchMoveStopPropagation: false,
            });

            // Stop the interval once the element is found
            clearInterval(checkprodVisitedSwiperExist);
        }
    }, 100); // Check every 100 milliseconds
});

/** Store locator - show/hide store details */
function showStoreInfoDetails(id) {
    $('.section-link-bottom').not('#' + id).removeClass("opened");
    $('.section-link-bottom#' + id).toggleClass("opened");

    $('.holder_imgs_img_active').empty();

    if ($('.section-link-bottom#' + id).hasClass('opened')) {
        var holderImgs = $('.section-link-bottom#' + id).find('.holder_imgs');

        if (holderImgs.length) {
            holderImgs.clone().appendTo('.holder_imgs_img_active');
        }
    }
}

/***@ js for guide entretien page */
$(function() {
    /*****************************************/
    /* PRIMARY NAVBAR *********************/
    if ($('.primary_navbar').length && !$('.nav_bar_frozen').length) {
        $('.navbar_tab').click(function () {
            var tab = $(this).data('tab');
            // Identify navbar to handle multiple primary_navbar occurences
            var navbar = $(this).parent().attr('data-navbar');

            /* change nav active on this elem **/
            $(this).parent().find($('.navbar_tab')).removeClass('active');
            $(this).addClass("active");

            /* If text should be present **/
            if (!$('.no_text').length) {
                $(".navbar_cnt[data-navbar='" + navbar +"']").removeClass('actif');
                $(".navbar_cnt[data-nav='" + tab + "'][data-navbar='" + navbar +"']").addClass('actif');
            }

            /* If nav tabs have specific content to show **/
            if ($('.tab_content').length) {
                $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif')
                $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
            }
        });
    }

    /***@ Guide Entretien specificites matiere */
    setTimeout(function() {
    var nav_holder_inside = new Swiper('.nav_holder_container', {
        slidesPerView: 'auto',
        // spaceBetween: 30,
        nextButton: '.swiper-navArrow-button-next',
        prevButton: '.swiper-navArrow-button-prev',
      });
    }, 800);

    $('.content_sidenav .sidenav_tab').click(function () {
        /* recover tab on click */
        var tab = $(this).attr('content-tab');

        if ($(this).hasClass("actif")) {
            $(this).removeClass("actif");
            $('.content_tab[content-tab="' + tab + '"]').removeClass("actif");
        } else {
            $(this).addClass("actif");
            $('.content_tab[content-tab="' + tab + '"]').addClass("actif");
        }
    });
});

$(window).on("load", function() {
    $('.navbar_tab:first-of-type').click();
});

$(function() {
    /**
     * Retirer border et padding dans le div .order_recap
     * si le div .upselling_panier exist
     * dans la page panier
     */
    var upsellingPanier = $('body.cart .elem_tunnel.right_side .upselling_panier');
    var orderRecap = $('body.cart .elem_tunnel.right_side .order_recap');

    if (upsellingPanier.length) {
        if (orderRecap.hasClass("addborder")) {
            orderRecap.removeClass("addborder");
        }
    } else {
        orderRecap.addClass("addborder");
    }
});

// Reveals addToCart btn clone on scroll
function revealClone(orig, clone) {
    var win_offset = parseFloat($('body').css('padding-top'));
    var origName = orig;
    orig = document.getElementById(orig);
    clone = document.getElementById(clone);

    if (orig && clone) {

        output = Math.min(orig.offsetTop - (window.pageYOffset + win_offset), 0);
        output = Math.max(orig.offsetTop - (window.pageYOffset + win_offset), -orig.offsetHeight);

        //fix affichage bouton ajout panier au scroll
        output = 0;
        if (orig.getBoundingClientRect().top < 0) {
            output = -orig.offsetHeight;

            if (!$('body').hasClass('cloned_'+origName)) {
                $('body').addClass('cloned_'+origName);
            }

        } else {
            if ($('body').hasClass('cloned_'+origName)) {
                $('body').removeClass('cloned_'+origName);
            }
        }

        document.body.style.paddingBottom = (-output) + 'px';
        clone.style.marginTop = output + 'px';
    }
}

$(document).ready(function() {
    // Select the second .home-column .home-module-cta a
    var $secondChild = $('.image-image-image .home-column').eq(1).find('.home-module-cta a');

    // Select the third .home-column .home-module-cta .slide_video
    var $thirdChild = $('.image-image-image .home-column').eq(2).find('.home-module-cta .slide_video');

    // Check if both elements are present
    if ($secondChild.length && $thirdChild.length) {
        // Create a temporary holder for one of the elements
        var $temp = $('<div>').hide(); // Create a hidden div for temporary storage

        // Swap the elements
        $secondChild.before($temp);       // Insert temporary div before the second element
        $thirdChild.after($secondChild);  // Move second element after the third element
        $temp.replaceWith($thirdChild);   // Replace temporary div with the third element
    }
});

/**** ACTION CTA CODE PROMO ***/
$(function () {
    $('#input_codePromo').on("change keyup",function(){
        if ($(this).val().length !=0) {
            $("#clientPromoErr").removeClass('cache');
            $(".form_submit #btn_cmd_valid").addClass('disable');
        }else{
            $("#clientPromoErr").addClass('cache');
            $(".form_submit #btn_cmd_valid").removeClass('disable');
        }
    });

     $('.w-submit-button').on("click",function(){
        $("#clientPromoErr").addClass('cache');
        $(".form_submit #btn_cmd_valid").removeClass('disable');
    });
});

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var numDIVObjet = document.getElementById("popup_numero_retour");

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    $("#popup_numero_retour").addClass('open');

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({scrollTop: 0}, (speed * 2), function () {});

    $("body").css('overflow', 'hidden');
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, id_transporteur) {

    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form').serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert( ajax_file( path_relative_root + 'ajax_sprintf.php?arg1=js_error_return_label ' ) );
            } else {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    var has_errors = false;

    $(formId+" .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form').serialize();
        data += '&idOrder=' + (multiTransp?idOrderTransp:idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}

setTimeout(function(){
    $('#shade, #general_shade, .shade, .btn_close, #popup_numero_retour .valid').click(function(){
        if ($('#popup_numero_retour').hasClass('open')){
            $("#general_shade").hide();
            $("#popup_numero_retour").removeClass('open').hide();
            $("body").css('overflow', 'auto');
        }
    });
},500);

function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("noscroll");

    if (!$("#shade").hasClass("actif")) {
        $("#shade").addClass("actif");
    }
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });
    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {

        closeMultiShad(id);
    });

    if (id === "country_switcher_lightbox") {
        $("#" + id).addClass('actif');
    }
}

function closeMultiShad(id) {

    $('#shade').off('click');

    $("#" + id).removeClass("actif");
    $("#shade").removeClass("actif");
    $("body").removeClass("noscroll");

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {

                $('#shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    if (id === "country_switcher_lightbox") {
        $("#" + id).removeClass('actif');
        if ($('#country_switcher_lightbox input[data-choice="choice_country"]:checked').length) {
            $.ajax({
                url: path_relative_root + "ajax_set_session_country_code.php",
                type: "post",
                data: {value: $('#country_switcher_lightbox input[data-choice="choice_country"]:checked').data('country-code')},
                success: function (res) {},
            });
        }
    }
}

// custom function to trigger lightbox close on relay change
function remove_shad() {
    setTimeout(function() {
        $("#shade").click();
    }, 300);
}

/*
 * [Display Press lightbox]
 */
$(".pressImg").on("click", function () {

    "use strict";

    $("#pressLightboxVisu").html("");
    $(this).clone().appendTo("#pressLightboxVisu");
    openMultiShad("pressLightbox");
});

// Increases or decreases item quantity based on ID
function changeQte(type) {

    var types = ['plus', 'less'], item, loader, qtty, prodId;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $('#cart_btn').data('cart', response);

                /**
                 * Move out of stock products to the top of the cart
                 */

                updateCartElements();
            }
        });
    }
}

$(document).ready(function(){
    var voirplus = $('.seen_more').find('p').html();
    $('.seen_more').each(function(){
        if ($(this).find('h2').html() === ""){
            $(this).find('h2').hide;
        }
        if ($(this).find('p').html() === ""){
            $(this).find('p').hide;
        }
        if ($(this).find('h2').html() === "" && $(this).find('p').html() === ""){
            $(this).hide();
            $('.showMoreBtn').find('.read-less').hide();
            $('.showMoreBtn').find('.read-more').hide();
        }
    })
    $('.showMoreBtn').find('.read-less').hide();
    $('.seen_more').removeClass('untoggled').animate({
            height: '6.1rem' });
    $('.seen_more').each(function() {
        if ($(this).hasClass('untoggled')){
            $(this).addClass('toggled');
            $(this).removeClass('untoggled').animate({
                height: '6.1rem'
                });
            $(this).find('.read-less').hide();
            $(this).find('.read-more').show();
        }
    })

    $('.showMoreBtn').on('click', function(e) {
        e.preventDefault;
        if ($(this).prev('.seen_more').hasClass('untoggled')){
            $(this).prev('.seen_more').addClass('toggled');
            $(this).prev('.seen_more').removeClass('untoggled').animate({
                height: '6.1rem'
                });
            $(this).find('.read-less').hide();
            $(this).find('.read-more').show();
            } else {
            $(this).prev('.seen_more').removeClass('toggled');
            $(this).prev('.seen_more').addClass('untoggled').animate({
                height: $(this).prev('.seen_more').get(0).scrollHeight
                });
            $(this).find('.read-more').hide();
            $(this).find('.read-less').show();
            };
        })

        if ($('body.checkout').length && $('#paiement_3Times_cofidis').length){
            $('#paiement_3Times_cofidis .bank_subtitle, #paiement_3Times_cofidis .radio').on('click', function(){
                $('#paiement_3Times_cofidis h3.accordion_title').click();
            });
        }
});

var iframe_max_height = $('#super_home_swiper .slide_video iframe').width();
$('#super_home_swiper .slide_video iframe').css('height', iframe_max_height);

/**
 * Permet de calculer les mensualités d'un paiement Cofidis 3x
 * @param {string} amount
 */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if (amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                var montant = Number.parseFloat(resJson.montant).toFixed(2).toString().replace('.', ',');
                var mensualite = Number.parseFloat(resJson.mensualite).toFixed(2).toString().replace('.', ',');
                var derniere_mensulatie = Number.parseFloat(resJson.derniere_mensulatie).toFixed(2).toString().replace('.', ',');
                var montant_du = Number.parseFloat(resJson.montant_du).toFixed(2).toString().replace('.', ',');
                $('#tab_mensualites .montant .tab_amount span').html(montant);
                $('#tab_mensualites .mensualite_1 .tab_amount span').html(mensualite);
                $('#tab_mensualites .mensualite_2 .tab_amount span').html(derniere_mensulatie);
                $('#tab_mensualites .montant_du .tab_amount span').html(montant_du);
                $('#tab_mensualites .frais .tab_amount span').html(resJson.frais);
            }
        });
    }
}

function cofidisScrollbar() {
    // Wait for _app modBoxOpen to open lightbox
    setTimeout(function() {
        if ($('#popup_cofidis3cb .popup_container').length) {
            OverlayScrollbars(document.querySelector('#popup_cofidis3cb .popup_container'),{});
        }
    }, 800);
}

function submitToCalcul(e) {
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}

var assoSwiperParams = {
    slidesPerView: 1,
    prevButton: '.prevAssocProd',
    nextButton: '.nextAssocProd',
};

// Handles opening and closing of accordion tab
function onAccordionClick() {

    var $this;

    if (!is_accordion) {

        is_accordion = true;

        if ($(this).parent().hasClass('opened')) {

            $(this).siblings('.accordion_contents').slideUp(200, accordionReset.bind(this, $.fn.removeClass));
        } else {

            if ($(this).closest('.accordion_container').find('.opened').length > 0) {

                $(this).closest('.accordion_container').find('.opened').each(function () {

                    $this = $(this);

                    $this.children('.accordion_contents').slideUp(200, function () {

                        $this.removeClass('opened');
                    });
                });
            }

            $(this).parent().addClass('opened');
            $(this).siblings('.accordion_contents').slideDown(200, accordionReset.bind(this, $.fn.addClass));
        }
    }
}

function checkProductInWishlist() {
    var productId = $("#produit_id").val();
    var couleurProd = $("#prod_couleur");
    if (couleurProd.length) {
        couleurProd = $("#prod_couleur").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#prod_taille");
    if (tailleProd.length) {
        tailleProd = $("#prod_taille").val();
    } else {
        tailleProd = 0;
    }

    if (typeof productId != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': productId, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 */
function changeBtnAddWishlist(in_wishlist) {
    if (typeof(button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }
    if (in_wishlist) {
        $("#addToWishlistButton").attr("onclick", "window.location.assign(path_relative_root + create_link('wishlist'))");
        $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event);
        $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

// Datalayer v2
function dataLayer_associated_product () {
    if (window.$('#carousel_wrapper')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if ( typeof data_layer_products != 'undefined' && data_layer_products.length > 0 ) {
                data_layer_products.forEach(function(item){
                    if ( item.id == product_id ) {
                        productObj = item;
                    }
                })
            } else if ( typeof data_layer_products_aimerez != 'undefined') {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if ( values[i].id == product_id ) {
                        productObj = values[i];
                    }
                }
            }
            var prod = productObj;

            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

if ($('body').hasClass('order_cgv')) {
    $(".content").each(function () {
        $(this).find("table").wrap('<div class="table_data"></div>');
    });
}

function modBoxFacilityPay() {
    modBoxOpen.call( document.getElementById( 'popup_facilitypay' ) );
}

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    if (array_wishlist_product_ids.length > 0) {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            }
        });
    }
}

/* Tunnel FAQ dynamic - Keep last section (contact info) open on page load and hide the question */
$( document ).ready( function() {
    if ( $( 'body.cart' ).length && $( '.bloc_question' ).length ) {
        $( '.faq_theme_wrapper' ).last().find( '.intitule_question' ).hide();
        $( '.faq_theme_wrapper' ).last().find( '.texte_question' ).show();
    }
});

function swipersTG() {
    // Add class blockTransform to .swiper-wrapper when clicked on '.color_achat_express, .button.valid'
    if ($('body.mobile.homepage').length) {
        $('.color_achat_express, .remaining_colors, .button.valid').click(function() {
            if ($('.rollover.achat_express').length) {
                $('.swiperTg_2 .tg2-wrapper, .swiperTg_3 .tg3-wrapper, .form_itm.check.size label, .swiperTg_2 .swiper-slide').addClass('blockTransform');
                $('#shade').css('display','block');
                $('.modal_shade').css('opacity','1');
            }
        });

        // remove class blockTransform to .swiper-wrapper when clicked on '.rollover.achat_express .close_modal''
        $('.rollover.achat_express .close_modal, .form_itm.color label, .modbox_buttons .form_submit').click(function() {
            if ($('.swiperTg_2 .tg2-wrapper, .swiperTg_3 .tg3-wrapper, .swiperTg_2 .swiper-slide').has('.blockTransform')) {
                $('.swiperTg_2 .tg2-wrapper, .swiperTg_3 .tg3-wrapper, .swiperTg_2 .swiper-slide').removeClass('blockTransform');
                $('#shade').css('display','none');
                $('.modal_shade').css('opacity','0');
            }
        });

        // remove class visible to #shade when clicked on '.rollover.achat_express .close_modal''
        $('.rollover.achat_express .close_modal, .form_itm.color label, .modbox_buttons .form_submit').click(function() {
            if ($('#shade').has('.visible')) {
                $('#shade').removeClass('visible');
            }
        });
    }

    setTimeout(() => {
        // Page rayon shad close pop up achat express
        if ($("body").hasClass("category") || $("body .dynasearchwrapper").length > 0) {
            if ($("#shade.colorAchatExpress").length > 0) {
                $("#shade.colorAchatExpress").click();
            }
        }
    }, 50);
}

$(function () {
    /* Initializes the swiper for the 2-image swiper: slider-module and takes into account multiple uses of the same module */
    if ($('.mobile .home-module.images-2').length) {
        let sliderModuleCount = 1;

        $('.mobile .home-module.images-2').each(function () {
            let newSwiperClass = 'swiperimages-2-' + sliderModuleCount;

            $(this).find('.swiperimages-2').addClass(newSwiperClass);

            // Destroy previous Swiper instance
            let prevSwiper = $(this).find('.swiperimages-2').data('swiper');
            if (prevSwiper) {
                prevSwiper.destroy(true, true);
            }

            if ($(this).find('.swiper-slide').length > 2) {
                let mySwiper = '.' + newSwiperClass;

                const swiper_slider_module_two_images = new Swiper(mySwiper, {
                    slidesPerView: 2,
                    spaceBetween: 7,
                });
            }

            ++sliderModuleCount;
        });
    }

    /* Initializes the swiper for the 3-image swiper: slider-module and takes into account multiple uses of the same module */
    if ($('.mobile .home-module.images-3').length) {
        let sliderModuleCount = 1;

        $('.mobile .home-module.images-3').each(function () {
            let newSwiperClass = 'swiperimages-3' + sliderModuleCount;

            $(this).find('.swiperimages-3').addClass(newSwiperClass);

            if ($(this).find('.swiper-slide').length > 2) {
                let mySwiper = '.' + newSwiperClass;

                const swiper_slider_module_three_images = new Swiper(mySwiper, {
                    slidesPerView: 2,
                    spaceBetween: 7,
                });
            } else {
                // Destroy existing Swiper if it exists
                let existingSwiper = $(this).find('.swiperimages-3')[0].swiper;
                if (existingSwiper) {
                    existingSwiper.destroy(true, true);
                }
            }

            ++sliderModuleCount;
        });
    }

    $('.nav_holder .swiper-container').each(function() {
        if ($(this).find('.swiper-slide').length > 4) {
            var swiper = new Swiper($(this), {
                slidesPerView: 'auto',
                loop: false, // Set loop to false
            });
        }
    });

    // Add class customer_stock_alert to body when alert stock is available
    if ($('body.customer').length && $('.right-container.wrapper_content_stock_alert').length) {
        $('body.customer').addClass('customer_stock_alert');

        // Override _app to remove semi-colon from taille
        $('.sub_taille').each(function() {
            $(this).text($(this).text().replace(':', ''));
        });
    }

    //  Remove "/ Invité" from sub-title only for Ventes privilege login
    if ($('body.customer.body_login').length && $(".newlogin_page.vp").length) {
        let title = $('.login_form_wrapper .title h3').text();
        title = title.replace(" / Invité", "");
        title = title.replace(" / Guest", "");
        $('.login_form_wrapper .title h3').text(title);
    }

    setTimeout(() => {
        $("#rechercheBtn").on("click touch", function() {
            toggleMenu('left', 'left');

            setTimeout(() => {
                $('#top_searchV2').addClass('activated').css('display','block');
                $("body").removeClass('search_hidden').css('overflow','hidden');
                $(".banner_wrapper, .bandeauMenuAffix").css('z-index', 105);
                $(".banner_wrapper").css('overflow', 'unset');
                $('#shade').addClass('search visible');
            }, 500);
        });

    }, 550);

    // Show/hide header on scroll -> for product page
    if ($('body.product_page').length) {
        $(window).scroll(function() {
            checkScrollHideHeader();
        });

        if ($('.noSizeSelected').length === 0) {
            var noSizeSelectedText = '<div class="error_text noSizeSelected" style="display: none;">' + Translator.translate('size_not_selected_error') + '</div>';

            $('.product_fields.quantity_filter.displayTwo').after(noSizeSelectedText);
        }

        $('select#prod_taille').on('change', function() {
            if ($(this).val() !== "") {
                $('.noSizeSelected').removeClass('error');
            }
        });
    }

    // remove shad on page 'panier'
    $('.cart .close_modal').click(function() {
        if ($('#shade').length) {
            $('#shade').removeClass('visible');
        }
    });

    /* SEO rayon */
    if ($("#push_rayon .txtWrapper").length) {
        $(document).ready(function () {
            var fullTextElement = $('.txt.full-text');
            var fullText = fullTextElement.html().trim();
            var maxLength = 258;

            $('#push_rayon .txtWrapper').css('opacity', '1');

            function truncateHtml(text, maxLength) {
                var trimmedText = "";
                var currentLength = 0;
                var regex = /(<\/?[^>]+>|[^<]+)/g;

                var match;
                while ((match = regex.exec(text)) !== null) {
                    if (match[0].startsWith("<")) {
                        trimmedText += match[0];
                    } else {
                        if (currentLength + match[0].length > maxLength) {
                            trimmedText += match[0].slice(0, maxLength - currentLength);
                            trimmedText += "...";
                            break;
                        }
                        trimmedText += match[0];
                        currentLength += match[0].length;
                    }
                }
                return trimmedText;
            }

            if (fullText.length > maxLength) {
                var trimmedText = truncateHtml(fullText, maxLength);

                fullTextElement.html(trimmedText);
                fullTextElement.append($('.seeMore')).show();
                $('.seeMore').show();

                $('.seeMore').on('click', function () {
                    $('.seeMore').insertBefore($('.seeLess'));
                    fullTextElement.html(fullText);
                    $('.seeMore').hide();
                    $('.seeLess').show();
                });

                $('.seeLess').on('click', function () {
                    fullTextElement.html(trimmedText).append($('.seeMore'));
                    $('.seeMore').show();
                    $('.seeLess').hide();
                });
            }
        });
    }

    swipersTG();

    // Page rayon shad close pop up achat express
    $("#shade").on("click tap touch", function() {
        if ($("body").hasClass("category") || $("body .dynasearchwrapper").length > 0) {
            if ($(this).hasClass("isExpress") || $(this).hasClass("colorAchatExpress")) {
                setTimeout(() => {
                    $(".rollover.achat_express").each(function() {
                        if ($(this).css("display") === 'block') {
                            $(this).css("display", "none");
                            $(this).find(".ligne_form").removeClass('actif');
                        }
                    });
                }, 100);

                $(this).removeClass("isExpress visible");
            }
        }

        if ($(this).hasClass('search visible')) {
            $('.dynasearchwrapper').addClass('close_search');
        }
    });

    $('#shade').click(function() {
        // Perform the actions once
        $("#shade").removeClass("visible").removeClass("isExpress");
        $(".rollover.achat_express").find('.actif').removeClass('actif');
        $(".noSizeAvailable").css('display', 'none');
        $(".bloc_add_alert").css('display', 'none');
    });

    if ($('#content_product .prod_wishlist_btn').length) {
        $('#content_product .prod_wishlist_btn').prependTo('.wishlist_and_sharers_wrapper');
    }

    if ($('#search_cp').length > 0) {
        var spanElement = $('<span class="search_cp_or_locate_me">').text(Translator.translate('search_cp_or_locate_me'));

        $('#search_cp').after(spanElement);
    }

    setTimeout(() => {
        toggleSubtitleAndSwiper();
    }, 500);

    updateDatalayerChangeSize();

    setTimeout(() => {
        // Manage create password on bankThanks
        if ($('body').hasClass('bankThanks') && window.password_validator_config.disable_btn_wrong_password) {
            const validatePassword = (password) => {
                let pattern = "^";
                const config = window.password_validator_config;

                if (config.uppercase) pattern += "(?=.*?[A-Z])";
                if (config.lowercase) pattern += "(?=.*?[a-z])";
                if (config.digit) pattern += "(?=.*?[0-9])";
                if (config.special) pattern += "(?=.*?[#?!@$%^&*-])";
                if (config.min_length > 0) pattern += `.{${config.min_length},}`;
                pattern += "$";

                return new XRegExp(pattern).test(password);
            };

            // Manage submit button
            const toggleButtonState = () => {
                const clientPwd = $('input[name="clientPwd"]').val();
                const clientPwdv = $('input[name="clientPwdv"]').val();
                const isValid = validatePassword(clientPwd) && validatePassword(clientPwdv) && (clientPwd === clientPwdv);
                $('#btn_pwd').attr('disabled', !isValid);
            };

            $('input[name="clientPwd"], input[name="clientPwdv"]').on('input', toggleButtonState);
        }
    }, 500);

    //Load only if the page has the class '.in_tunnel'
    if ($('.tunnel').length) {
        $(".top_nb_products").on("click", function () {
            toggleListProduct($(this));
        });
    }

    /**
     * Tunnel V3 Switch toggle details line position
     */
    if ($(".total_produit.toggle").length) {
        switchToggleCartpos();
    }

    if ($("#blocs_address").length || $(".wrapper_payment").length) {
        switchAddrRecapCartpos();
    }

    /**
     * Add new alias
     */
    $('#addAliasListElem').on('click', function () {

        var hosted_worldline = $('.hosted_worldline');

        $('.alias_list li').removeClass('selected');
        $('html, body').animate({
            scrollTop: hosted_worldline.offset().top - 150
        }, 500);
    });
});

// Function to check and toggle the display of modbox_subtitle and initialize Swiper
function toggleSubtitleAndSwiper() {
    if ($('body').hasClass('homepage') || $('body').hasClass('category')) {
        const $swiperSlides = $("#association_modbox .swiper-slide");
        const $modBoxPagination = $(".modBox-pagination");
        const $modBoxSubtitle = $("#modbox_subtitle");

        if ($swiperSlides.length > 2) {
            $modBoxPagination.css("display", "block");

            // Check if the Swiper instance is already initialized to avoid re-initializing
            if (!$(".modbox.swiper-container").hasClass("swiper-initialized")) {
                var rayonSwiper = new Swiper(".modbox", {
                    slidesPerView: 2.33,
                    spaceBetween: 7,
                    pagination: {
                        el: '.modBox-pagination',
                    },
                });
            }
        } else {
            $modBoxPagination.css("display", "none");
        }

        // Toggle visibility of modbox_subtitle based on the existence of swiper-slide elements
        if ($swiperSlides.length) {
            $modBoxSubtitle.css("display", "block");
        } else {
            $modBoxSubtitle.css("display", "none");
        }
    }
}

// Initialize the MutationObserver to watch for changes in association_modbox
const observer = new MutationObserver(mutations => {
    mutations.forEach(() => {
        toggleSubtitleAndSwiper(); // Call the function whenever there's a mutation
    });
});

// Configuration for the observer: observe child list changes
const config = { childList: true };

// Start observing association_modbox for changes
const targetNode = document.querySelector("#association_modbox");
if (targetNode) {
    observer.observe(targetNode, config);
}

// Initial timeout for first Swiper and display check
setTimeout(() => {
    toggleSubtitleAndSwiper();
}, 500);

function updateDatalayerChangeSize(event) {
    // Check if the page has the class 'category' in the body tag
    if (!document.body.classList.contains('category')) {
        return; // Exit the function if not on a 'category' page
    }

    // Use 'event.target' to reliably access the form element
    const targetElement = event?.target || this;

    // Check if 'targetElement' is defined and has a form property
    if (!targetElement || !targetElement.form) {
        return; // Exit the function if 'targetElement' or its form is undefined
    }

    // Retrieve the number of sizes from the form
    const cntSizesElement = targetElement.form.elements['cnt_sizes'];
    const nbSizes = cntSizesElement ? cntSizesElement.value : 0;

    // Update the DataLayer if 'nbSizes' is valid and greater than 1
    if (window.dataLayer !== undefined && nbSizes > 1) {
        const sizeValue = targetElement.dataset.nom;
        window.dataLayer.push({
            'event': 'changeSize',
            'produit': {
                'size_value': sizeValue,
            }
        });
    }
}

function openColorAchatExpressV2() {

    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }

    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');
        $("#shade").removeClass("colorAchatExpress visible").css('opacity', '');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
        $("#shade").addClass("colorAchatExpress visible").css("opacity", "1");
    }

}

// Function to check if user has scrolled down to display header
function checkScrollHideHeader() {
    var scrollPosition = $(window).scrollTop();
    var triggerDistance = 10;

    // Check if scroll position is greater than the trigger distance
    if (scrollPosition > triggerDistance) {
        $('.bandeauMenuAffix').show();
        $('#site_head').show();

        //sliderBandos top swiper mobile
        let swiperName = $(".banner_wrapper #sliderBandos .swiper-slide");
        let checkSwiperOn = $("#sliderBandos").hasClass("swiper-container-vertical");

        if (swiperName.length > 1 && !checkSwiperOn) {
            var bandoHeaders = new Swiper(".banner_wrapper #sliderBandos", {
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                direction: "vertical",
                autoplay: 3000,
                speed: 2200,
                autoplayDisableOnInteraction: false
            });
        }
    } else {
        $('.bandeauMenuAffix').hide();
        $('#site_head').hide();
    }
}

/* Want shad to be visible for search taille on homepage */
function shadAchatExpressNotVisible() {
    $("#shade").addClass("isExpress").addClass("visible");
}

//* To add additional functions or code during the update step from the app */
function additionalFunctionParam() {
    $('.dynasearchwrapper').addClass('close_search');
}

function openSizeAchatExpressV2() {
    var form = this.form;
    var $div_color = $(form).find('.productColorFieldset');
    var $div_size = $(form).find('.productSizeFieldset');
    var size_id = $(form).find('input[name="tailleProd"]').val();
    var $div_alert_stock = $(form).find('.bloc_add_alert');
    var $div_size_not_available = $(form).find('.noSizeAvailable');

    $div_alert_stock.hide();
    $div_size_not_available.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxColors = lightbox.hasClass('displayColors');

    if (lightBoxColors) {
        lightbox.removeClass('displayColors');
    }

    if (size_id !== 'none') {
        form.elements['itm_size'].checked = false;
    }

    shadAchatExpressNotVisible();

    if ($div_size.hasClass('actif')) {
        $(form).find('.achat_express').hide();
        $div_size.removeClass('actif');
    } else {
        $div_color.removeClass('actif');

        if (size_id === 'none') {
            sendFormUpdateCart.call(form);
        } else {
            $(form).find('.achat_express').show();
            $div_size.addClass('actif');
        }
    }

    var product_id = parseInt($(form).find('input[name=produit_id]').val());

    if (array_colisages[product_id] == undefined) {
        var array_product_ids = [];
        var array_products = $(document).find('input[name=produit_id]');

        Object.values(array_products).forEach(function (value) {
            if (value.id && value.id.indexOf('produit_id_') >= 0 && array_colisages[$(value).val()] == undefined) {
                array_product_ids.push($(value).val());
            }
        });

        if (array_product_ids.length > 0) {
            var data = {produit_id: array_product_ids};

            if (
                (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) ||
                (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false)
            ) {
                data.warehouse_only = 1;
            }

            $.ajax({
                url: path_relative_root + 'ajax_get_colisages_states_by_color_size.php',
                type: 'post',
                data: data,
                async: false,
                success: function (res) {
                    try {
                        res = $.parseJSON(res);
                    } catch (e) {
                        console.error("parseJSON");

                        return;
                    }

                    if (res.success && res.result) {
                        Object.entries(res.result).forEach(function(entry) {
                            const [idProduct, objProduct] = entry;
                            array_colisages[idProduct] = objProduct;
                        });
                    }
                }
            });
        }
    }

    if (array_colisages[product_id] != undefined) {
        var sizes_tab = array_colisages[product_id][$(form).find('input[name=couleurProd]').val()];
        var size_box, is_disabled;
        var has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $(form).find('[name="itm_size"]').filter('[value="' + size_id + '"]');

                var size_label = size_box.next('label');
                var child_no_stock = size_label.find('.no_stock');

                is_disabled = !sizes_tab[size_id];

                if (!is_disabled) {
                    has_stock = true;
                    child_no_stock.hide();
                    size_box.removeClass('disabled');
                } else {
                    size_box.addClass('disabled');

                    if (child_no_stock.length > 0) {
                        child_no_stock.show();
                    } else {
                        var spanElement = $('<span>' + Translator.translate('product_no_stock') + '</span>');

                        spanElement.addClass('no_stock');
                        size_label.append(spanElement);
                    }
                }
            }
        }
    }

    if (
        (typeof GEM_Services != 'undefined' && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE != 'undefined' && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE !== false) ||
        (typeof GEP != 'undefined' && typeof GEP.isOperatedByGlobale != 'undefined' && GEP.isOperatedByGlobale !== false)
    ) {

        $('.no_stock_entrepot').each(function () {
            if ($(this).html() == '') {
                if (!$(this).prev().hasClass('no_stock')) {
                    $(this).prev().addClass('no_stock');
                    $(this).prev().show();
                }
            } else {
                $(this).show();
            }
        });
    }
}

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});

function modBoxClose(evt) {
    if ($(this).attr('id') === 'choseCouponFreeProduct') {
        var loader = $('#formPanier .loader');

        if (loader.hasClass('loading')) {
            loader.removeClass('loading');
        }
    }

    if ($(this).attr('id') === 'item_error') {
        var item_sharing_ml = $('#item_sharing_ml');

        if (item_sharing_ml.hasClass('open')) {
            item_sharing_ml.removeClass('open');
        }
    }

    $("html,body").css('position','initial');

    var shade = $('#shade');
    var modal = ( $('.modbox.open').length ) ? $('.modbox.open') : $('.modal_box.open');

    if ( typeof evt !== "undefined" ) evt.preventDefault();

    shade.off('touchend', modBoxClose).removeClass('visible').removeClass('isExpress');
    modal.removeClass('open');

    // if the evt parameter is undefined, and 'this' still has display block, we delete it, and delete its #shade sibling
    // (applies to the know more modal on user signup, wich might be initialized with the wrong function?)
    if (typeof evt === "undefined" && $(this).css('display') === 'block') {
        $(this).fadeOut(300, function(){
            $(this).hide()
        });
        shade.fadeOut(300, function(){
            shade.hide()
        });
    }
}

function showSendfriendbox() {
    openMultiShad("sendfriendbox");
}

function closesendfriendmodal() {
    closeMultiShad("sendfriendbox");
}

function showsendwishlistmodal() {
    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}

// The generateNewBlocFilter() is used only on wrapper_filters_search.php via changeajaxfilterRayon() which is called on APP
function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
            value = getInputVal.bind(this),
            output = {},
            tmpoutput = {},
            is_from_page = false,
            key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if($(".filter_date").length > 0) {
            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if( typeof(output['date']) == 'undefined' ) {
                output.date = 4;
            } else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {
            this.nb_prod.value = nb_product;

        } else {
            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;

        const productLoaded = $('#list_item .item_container:not(".push")').length;
        const maxItems = document.getElementById('totalElems').value;

        if(productLoaded < maxItems) {
            output.current_page = page + 1;
        }
    }

    /** LEGACY **/

    //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        var tri = res[1][0];
    }

    /** END LEGACY **/

    if (!tri) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0 ) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    paginationGoToPage(1);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof(output['date']) == 'undefined') {
                    output.date = 4;
                } else {
                    if (output['date'] == 4) {
                        output['date'] = tmp_date;
                    }
                }
            } else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            paginationGoToPage(1);
        }
    });
}

function changeVisuZoom(field, path) {
    var elem = (document.getElementById('nyroModalImg')) ? 'nyroModalImg' : 'img_tmp';

    var w_tmp = $('#' + elem).width();
    var h_tmp = $('#' + elem).height();

    document.getElementById(field).innerHTML = "<div id='loader_tati' style='z-index:1;position:relative;top:400px;text-align:center;font-family:NeueKursivschriftLTPro-StehendS;font-size:16px;color:#E9568E;'><img src='" + path_relative_root + "img/loader.gif' /><p><strong>Chargement...</strong></p></div>";
    document.getElementById(field).innerHTML += "<div style='position:relative;z-index:2;top:-41px;'><img id='img_tmp' src=" + path + "></div>";

    $('#img_tmp').load(function () {
        $('#loader_tati').remove();
        $(this).parent().css('top', '0');
        $(this).width(w_tmp);
        $(this).height(h_tmp);
    });
}

// Refactoring needed
function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop) {
    const productLoaded = $('#list_item .item_container:not(".push")').length;
    const maxItems = document.getElementById('totalElems').value;
    var nb_prod = document.getElementById('nb_prod').value,
        nb_total_page = Math.ceil(maxItems / nb_prod);

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    var list_id_div = [];
    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');
        var nb_product = nb_product || parseFloat($('#nb_prod').val());

        let currentPage = $('#bottom_page').val();

        if (fromScrollTop === true) {
            // Reverse page direction if fromScrollTop
            currentPage = parseInt($('#top_page').val());
        }

        output.current_page = parseInt(currentPage);

    } else {
        var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
            value = getInputVal.bind(this),
            output = {},
            is_from_page = false,
            nb_product = nb_product || parseFloat($('#nb_prod').val()),
            type = type || $('#type').val(),
            idObj = idObj || parseFloat($('#idObj').val()),
            key;

        // Get all filters
        form = Array.prototype.slice.call(form.elements, 0);

        form.forEach(function(elm) {
            // Build an object to store active filters
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {
                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                    {
                        if (elm.name == 'brand') {
                            elm.name = 'promo'
                        }
                    }
                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }

                    output[elm.name].push(elm.value);
                }

            }
        });

        // Convert filters arrays to strings
        for (key in output) {
            if (output.hasOwnProperty(key)) {
                if (output[key].constructor === Array) {
                    output[key] = output[key].join('~');
                }
            }
        }

        if ($(".filter_date").length > 0) {
            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof(output['date']) == 'undefined') {
                output.date = 4;
            } else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if(productLoaded < maxItems) {
            output.current_page = page + 1;
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    // Add misc values to output object
    output.page = fromScrollBottom ? $(".item_container:not(.push)").length : this.page.value - document.getElementById('push_count').value;
    output.page = (output.page > 0) ? output.page : 0; // Fix page value if negative

    output.parentPath = path_relative_root;
    if (!use_new_filter_url) {
        output.idObj = idObj;
        output.type = type;
    }

    if (output.page != 0) {
        output.nb_prod = nb_product;
    } else {
        // Since we are on the first page, we need to reset the number of products to the default value because pushs are set only on the first page and are repeated manually on others
        output.nb_prod = $('#nb_prod_default').val();
    }

    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    output.handle_push = 'false';

    if ($('#prevNSAncre').length > 0) {
        let currentPage = $('#bottom_page').val();

        if (fromScrollTop === true) {
            // Reverse page direction if fromScrollTop
            currentPage = parseInt($('#top_page').val());
        }

        output.current_page = parseInt(currentPage);
    }

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
        output.multi_object = $('#multi_object').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    /** LEGACY **/
        //Recuperation du tri par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/
    if (tri != 0) {
        output.tri = tri;
    }

    if (page === 'all') {

        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function() {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function(res) {
                var old_elm = document.querySelector('.list_item');
                var content, itm_length;
                var is_in_wishlist = [];

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                changeSeoElementWithPagination(res);

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function(i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    if (typeof rayonCategSlider != 'undefined') {
                        rayonCategSlider.slideTo(rayonInitialSlide);
                    }
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $('.pagerNav.end_pagi .loader_scroll_new').fadeTo(100, 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;
                    
                    if (sessionStorage.getItem('infiniteScroll') !== 'true' || (fromScrollTop !== true && page < nb_total_page)) {
                        // Update pagination only when not fromScrollTop
                        paginate(page + 1);
                    }

                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item_container:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();
                        old_elm.insertAdjacentHTML('afterbegin', content);
                        $(document).scrollTop(firstMsg.offset().top - curOffset);
                        //L'inverse pour le bas
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseInt(output.nb_tot_prod) > parseInt(output.nb_prod)) {
                        //backToTop();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {}
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {}
                    }

                    /** END LEGACY **/

                    if (parseFloat($('#filters_menu').css('right')) === 0) {

                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload || typeof lazyload != 'undefined') {

                        lazyload.init();
                    }

                    if (typeof lazyloadImage === 'function') {
                        lazyloadImage();
                    }

                    if (typeof generateNewBlocProdExtra === 'function') {
                        generateNewBlocProdExtra();
                    }

                } else {
                    content = '<div class="no_results_wrapper">' +
                        '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                        Translator.translate('no_result_txt') +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    old_elm.innerHTML = content;
                }
                filtre_ajax();

                is_in_wishlist = (typeof res[9] === 'undefined') ? [] :  res[9].split(',');

                for (const product_id in is_in_wishlist) {
                    var pdt_id = (is_in_wishlist[product_id].includes('_')) ?  is_in_wishlist[product_id].split('_')[0] : is_in_wishlist[product_id];
                    if ($("div").find('[data-productid=' + pdt_id + ']').attr('data-productid') !== '') {
                        var id_div = $("div").find('[data-productid=' + pdt_id + ']').attr('id');
                        list_id_div.push(id_div);
                    }
                }
            },
            complete: function() {
                $('#totalElems').trigger('change');
                $('#page').trigger('change');

                var isInfinityScroll = $('#see_all').val() == 1;
                var clickingOnSeeAllProducts = nb_product === '1000' || nb_product === 'all';

                let maxItems = document.getElementById('totalElems').value;
                let productLoaded = $('#list_item .item_container:not(".push")').length;

                if (productLoaded == maxItems || productLoaded == 0) {
                    showSeeAll = 0;
                    $('.pagerNav.end_pagi .loader_scroll_new').hide(); // Hide "See all" button
                } else {
                    $('.pagerNav.end_pagi .loader_scroll_new').show();
                }

                $('.pagerNav.end_pagi #page_loader').hide();

                /**
                 * Retour vers le haut du rayon :
                 * uniquement dans le cas d'une pagination classique (see_all.val() == 0).
                 * Si initialisation du scroll infini (see_all.val() == 1), on ne remonte que de quelques
                 * px pour déclencher le chargement de la page suivante.
                 */
                if (!fromScrollBottom && !fromScrollTop) {
                    $('#is_loading').val('0');

                    if($('.item_container:not(.push)').length <= nb_product + 1) { // Exclude push in check

                        /**
                         * Dans le cas où le scroll infini est configuré
                         * et que l'on veut l'activer
                         */
                        if (isInfinityScroll) {
                            window.scrollTo(
                                0,
                                ($('#scroll_items').offset().top // Espace entre le haut de la page et le haut du block #scroll_items
                                    + ($('#scroll_items').height() // Hauteur totale du block #scroll_items
                                        - $('.item_container').height() // Hauteur d'un item de produit
                                        * 2) )
                            );
                        }

                        /**
                         * Dans le cas où le scroll infini n'est pas configuré
                         * et que l'on veut afficher tous les produits du rayon
                         */
                        else if (!isInfinityScroll && clickingOnSeeAllProducts) {
                            setTimeout(function() {
                                var itemHeight = $('.item_container:not(.push)').height(); // Hauteur d'un item de produit
                                var countPush = $('.item_container.push').length ? $('.item_container.push').length : 0;
                                var itemPushHeight = $('.item_container.push').length ? $('.item_container.push').height() : 0;
                                var changeViewEnabled = $('#toggleNumObjects').length; // Possibilité de changer de vues

                                if (changeViewEnabled) {
                                    var isViewPerOne =  changeViewEnabled && $('#oneProduct').hasClass('actif'); // Vérif sur le mode de vue(s) du rayon
                                } else {
                                    var isViewPerOne =  1;
                                }

                                /**
                                 * Pour calculer le nombre de lignes de produits en vue par 2
                                 * on ajoute 1 si le nombre de produits est impair
                                 * pour avoir un réultat rond
                                 */
                                if (!(defaultNbProd % 2 == 0) && !isViewPerOne) {
                                    defaultNbProd = defaultNbProd + 1;
                                }

                                /**
                                 * La hauteur de la liste de produits
                                 * égale à la hauteur d'un item de produit
                                 * multipliée par le nombre de produits
                                 * ou le nombre de nombre de ligne de produits (en vue par 2)
                                 */
                                var defaultListItemsHeight = isViewPerOne ? itemHeight * defaultNbProd + (itemPushHeight * countPush) : itemHeight * (defaultNbProd / 2) + (itemPushHeight * countPush);

                                window.scrollTo(
                                    0,
                                    defaultListItemsHeight
                                );
                            }, 100);
                        }

                        /**
                         * Dans le cas où l'on utilise la pagination
                         */
                        else {
                            backToTop();
                        }
                    } else {
                        setTimeout(function() {
                            if ($('#see_all').length > 0 && $('#see_all').val() == 0) {

                                $("html, body").animate({
                                    scrollTop: 0
                                }, 200);

                            } else {

                                $("html, body").animate({
                                    scrollTop: $(window).scrollTop() - 2
                                }, 200);

                            }
                        }, 250);
                    }
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                    initInfiniteScroll(true);

                    /**
                     * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                     * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                     */

                    if ($('body.category').length) {
                        var origin = $('.item_container:not(.push)').find('img.watched'),
                            target = $('span.ill_img'),
                            targetHeight = origin.height();

                        target.each(function() {
                            $(this).css({ minHeight: targetHeight });
                        })
                    }
                }

                if ($("body.category").length) {
                    addClickTrigger(page+1);
                }

                if ($("#scroll_items #list_item .no_results_wrapper").length) {
                    // Hide pagination when no result
                    $('.pagerNav.end_pagi').hide();
                } else {
                    // Show pagination when result available
                    $('.pagerNav.end_pagi').show();
                }

                setTimeout(function() {

                    var loader = $('.button + .loader');

                    if (loader.hasClass('loading')) {

                        loader.removeClass('loading');
                    } else {

                        loader.parent().removeClass('loading');
                    }

                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {

                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                        toggleMenu(menuId);
                    }
                }, 200);

                if (list_id_div.length > 0) {
                    for (const class_update in list_id_div) {
                        $("#" + list_id_div[class_update]).removeClass('addToWishlistButton');
                        $("#" + list_id_div[class_update]).addClass('existToWishlistButton');
                    }
                }
            }
        });
    }
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form),
        id = value('type_nav', 'tous');
    var type = value('type_tri'),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById('wrapper_couleur_filtre').innerHTML = filterformColors;
            document.getElementById('wrapper_taille_filtre').innerHTML = filterformSize;
        }

        form.reset();
        
        // Force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP
        $('#filters_menu input[checked]').not('#wrapper_sscat_filtre input[checked]').removeAttr("checked"); 
    }

    $('legend.open', form).each(function() {
        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {
        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {
            type = 'obj';
            $('#object_id').val(idObjTmp);
        } else {
            type = 'subcat';
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('button')) {
        btn.nextElementSibling.style.display = 'block';
    }

    // Get new elements
    if (from_type == 'product') {
        paginationGoToPage.call(this.form, 1);
    } else {
        generateNewBlocSearch.call(form, value('nb_prod'), 0, btn, 0, 0, type, idObj);
    }

    toggleMenu('filters', 'right');

    // Prevent default action
    return false;
}

function initToggleCartTypeBtn() {
    if ($(".toggleCartTypeBtn").length) {
        let toggleCart = $(".toggleCartTypeBtn");
        let cartContent = $(".cart_main_table.basket");
        let wishContent = $(".cart_main_table.wishlist");
        let cartSummary = $("#tunnel_right_col");

        toggleCart.on("touchend click", function () {
            toggleCart.removeClass("actif");
            $(this).addClass("actif");
            $(".cart_main_table").removeClass("displayed");

            if ($(this).hasClass("basket")) {
                cartContent.addClass("displayed");
                cartSummary.removeClass("noDisplay");
            } else {
                wishContent.addClass("displayed");
                cartSummary.addClass("noDisplay");
            }
        });
    }
}

function updateCartElements() {
    cartLoadMovement();
}

function removeFromBasketExtra() {
    getWishlistProducts();
}

function cartLoadMovement() {
    // init the cart/wishlist toggle button
    initToggleCartTypeBtn();

    // move Alma widget above the order validation button
    if ($('.formPanier_container').length && $('#alma-widget').length) {
        $('#alma-widget').appendTo('.formPanier_container');
    }

    // move the cart slider below the order recap
    if ($('#wrapper_cart_slider').length && $('.wrap_bottom_rightcol').length) {
        $('#wrapper_cart_slider').appendTo('.wrap_bottom_rightcol');
        setTimeout(function() {
            $('#wrapper_cart_slider').css('opacity', '1');
            $('#wrapper_cart_slider').css('height', '100%');
        }, 300);
    }

    // init the cart slider
    if ($('#cart_slider .swiper-slide').length > 2) {
        var likeSwiper = new Swiper('#cart_slider', {
            slidesPerView: 2.3,
            spaceBetween: 7,
            pagination: '.cart-slider-swiper-pagination',
        });
    }

    // move the text push below the question bloc
    if($('.pushBasketWrapperText').length) {
        $('.pushBasketWrapperText').insertAfter('.bloc_question');
    }

    // init alma widget
    if ($('#alma-widget').length > 0) {
        if ($('#montant_txt').length > 0) {
            $('#alma-widget').empty('');
            var amount = parseFloat($('#montant_txt').text().replace(/[^0-9.]/g, ''));
            initAlmaWidgetBasket(amount);
        }
    }

    // shows coupon form if no code is entered
 
    if (wShop.$refs.wCouponForm && !$(".bill_line.used_cp").length) {

        if ($(".step_1").length) {
                wShop.$refs.wCouponForm.internalShowForm = 1;
            } else {
                wShop.$refs.wCouponForm.internalShowForm = 0;
        }
    }
}

// Taken from JONAK
function changeVisu(field, path, evt) {}

/**
 * Handles the input element based on its type and class.
 * If the input type is 'password', it toggles the 'w-active' class
 * on the associated button based on the presence of the 'w-focused' class
 * on the input element.
 *
 * @param {HTMLInputElement} input - The input element to handle.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function handleInput(input) {
    if (input.type == 'password') {
        let idBtn = input.id + '_toggle';
        let btn = document.getElementById(idBtn);

        if (btn) {
            if (input.classList.contains('w-focused')) {
                btn.classList.add('w-active');
            } else {
                btn.classList.remove('w-active');
            }
        }
    }
}

/**
 * Toggles the visibility of a password input field.
 *
 * This function changes the type of the input field between 'password' and 'text',
 * effectively showing or hiding the password. It also updates the inner HTML of the
 * element that triggered the function to reflect the current state.
 *
 * @param {string} target - The ID of the password input field to toggle.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function toggleVisibilityPassword(button, target) {
    let inputPwd = document.getElementById(target);

    if (inputPwd) {
        let inputPwdType = inputPwd.type;
        if (inputPwdType === 'password') {
            inputPwd.type = 'text';
            button.innerHTML = Translator.translate('pwd_toggle_visibility_hide');
        } else {
            inputPwd.type = 'password';
            button.innerHTML = Translator.translate('pwd_toggle_visibility_show');
        }
    }
}

////////gmap_file////////
var geocodeur;
var infowindow = null;
var maCarte;
var markers = [];
var clusterer;
var type_call = "";
var markersArray = new Array();
var date = new Date();
var t_address;
var t_gotostore;
var t_sendinfo = Translator.translate('send_store_information');

// Permet de definir sur qu'elle page est appelé la carte des magasin
var magasin_gmap_page = "distributeur";

// Les coordonné de géolocalisation du client
var lng_client = '';
var lat_client = '';

// Should be a constants but are variables for compatibility reasons
var search_exceptions = ["nancy", "bayonne", "macon", "le cres"];
var zipcode_rex = new RegExp("^[0-9]+$");
var know_more_str = Translator.translate('cookies_texte_link');
// Translate
var close_txt = null;
var horaire_txt = null;

var grabber;

/*
 * Initialisation de la premiere carte google map
 * page : distributeur (page distributeurs.php), fiche_produit (une fiche produit pour la reservation en magasin)
 */
function initialize(page) {
    if (typeof page != "undefined") {
        magasin_gmap_page = page;
    }

    var inputAutocompleteId = "search_cp";
    var inputAutocompleteIdJq = "#" + inputAutocompleteId;
    var searchElem = document.getElementById(inputAutocompleteId);

    var option = {
        types: ["geocode"],
    };

    var autocomplete = new google.maps.places.Autocomplete(searchElem, option);

    $(".wrapper_retailers_map").animate({opacity: 1}, 600);

    t_address = Translator.translate("default_address");
    t_gotostore = Translator.translate('go_to_store');

    geocodeur = new google.maps.Geocoder();

    if ($("#retailers_map").length) {
        var latlng = new google.maps.LatLng(47.9, 1.9);
        var myOptions = {
            zoom: 5,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: google_map_style,
        };
        var mcOptions = {
            gridSize: 80,
            imagePath: path_relative_root + "img/maps/cluster",
        };

        maCarte = new google.maps.Map(
            document.getElementById("retailers_map"),
            myOptions
        );
        clusterer = new MarkerClusterer(maCarte, markers, mcOptions);

        markers = [];
    }

    var option = {
        types: ["geocode"],
        //,componentRestrictions: { country: [<?php echo $liste_pays_iso; ?>] }
    };

    var autocompleteElem = new google.maps.places.Autocomplete(
        document.getElementById(inputAutocompleteId),
        option
    );
    // When the user selects an address from the dropdown,
    // populate the address fields in the form.
    google.maps.event.addListener(autocompleteElem, "place_changed", function(
        e
    ) {
        submitGeoloc();
    });
}

/**
 * Function used when the user indicates a location
 */
function geocodeAdresse() {
    var adresse = "",
        departement = false;

    adresse = document.getElementById("search_cp").value.trim();

    // Cas special pour nancy il nous envoie aux US!!!
    if (search_exceptions.includes(adresse)) {
        adresse += " france";
    }

    if (zipcode_rex.test(adresse)) {
        adresse += ", France";

        document.getElementById("search_cp").value += ", France";
    }

    if (geocodeur != "undefined") {
        geocodeur = new google.maps.Geocoder();
    }

    geocodeur.geocode({address: adresse}, function(results, status) {
        // Si l'utilisateur ne saisit rien on se remet à la position initiale (on remet la premiere carte et tous les magasins)
        if (adresse == "") {
            if ($("#retailers_map").length) {
                var latlng = new google.maps.LatLng(47.9, 1.9);

                var myOptions = {
                    zoom: 5,
                    center: latlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: google_map_style,
                };
                var mcOptions = {
                    gridSize: 80,
                    imagePath: path_relative_root + "img/maps/cluster",
                };

                maCarte = new google.maps.Map(
                    document.getElementById("retailers_map"),
                    myOptions
                );
                clusterer = new MarkerClusterer(maCarte, markers, mcOptions);

                markers = [];
            }

            // On va rechercher tous les magasins
            rechercheProximite();
        } else if (type_call == "select_pays") {
            rechercheProximite();
        } else if (status == google.maps.GeocoderStatus.OK) {
            var pos = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
            };

            rechercheProximite(pos);
        } else {
            displayLoader("show");
            alert(adresse + " " + Translator.translate("txt_introuvable"));
        }
    });
}

function geolocalisation() {
    if ( lng_client != '') {
        var pos = {
            lat: lat_client,
            lng: lng_client
        };
        rechercheProximite(pos);
    } else {
        navigator.geolocation.getCurrentPosition(function(position) {
            lat_client = position.coords.latitude;
            lng_client = position.coords.longitude;
            var pos = {
                lat: lat_client,
                lng: lng_client,
            };

            rechercheProximite(pos);
        });
    }
}

var i = 0;

/**
 * Search for the different stores according to the selection criteria
 * @param {string} latlng
 */
function rechercheProximite(latlng) {
    var param = "";
    if (close_txt === null) close_txt = Translator.translate("store_close");

    var adresse = document.getElementById("search_cp").value;

    if (adresse.substr(0, 20) == Translator.translate("default_address_begin")) {
        adresse = "";
    }

    var param_sup = "";

    param_sup += "search=" + adresse + param;

    var marqueur_user = "";
    if (typeof latlng != "undefined") {
        // Pour la positionner l'utilisateur
        var image = new google.maps.MarkerImage(
            path_relative_root + "img/maps/map_position.png",
            // This marker is 20 pixels wide by 32 pixels tall.
            new google.maps.Size(24, 26),
            // The origin for this image is 0,0.
            new google.maps.Point(0, 0)
        );

        if (latlng.coords || (latlng.lat && latlng.lng)) {
            if (latlng.coords) {
                var lat_exact = latlng.coords.latitude;
                var lng_exact = latlng.coords.longitude;
            } else {
                var lat_exact = latlng.lat;
                var lng_exact = latlng.lng;
            }

            var requeteRecherche = path_relative_root + "ajax_generate_xml_map.php?lat=" + lat_exact + "&lng=" + lng_exact + "&" + param_sup;

            var point = new google.maps.LatLng(
                parseFloat(latlng.lat),
                parseFloat(latlng.lng)
            );
        } else {
            var requeteRecherche =
                path_relative_root +
                "ajax_generate_xml_map.php?lat=" +
                latlng.geometry.location.lat() +
                "&lng=" +
                latlng.geometry.location.lng() +
                "&" +
                param_sup;

            var point = new google.maps.LatLng(
                parseFloat(latlng.geometry.location.lat()),
                parseFloat(latlng.geometry.location.lng())
            );
        }

        marqueur_user = new google.maps.Marker({
            position: point,
            icon: image,
        });
    } else if (
        typeof lat_exact != "undefined" &&
        lat_exact != "" &&
        typeof lng_exact != "undefined" &&
        lng_exact != ""
    ) {
        var requeteRecherche =
            path_relative_root +
            "ajax_generate_xml_map.php?lat=" +
            lat_exact +
            "&lng=" +
            lng_exact;

        lat_exact = "";
        lng_exact = "";
    } else {
        $("#retailers_map").css("width", "100%");

        var requeteRecherche = path_relative_root + "ajax_generate_xml_map.php?" + param_sup;
    }

    no_result = false;
    // Pour ne le charger qu'une seul fois
    if (horaire_txt === null) horaire_txt = Translator.translate("horaires_map");

    // On effectue la requete suivant les filtres indiques
    downloadUrl(requeteRecherche, "50km", latlng, function(doc) {
        var xml = doc.responseXML;
        var marqueurs = xml.documentElement.getElementsByTagName("marqueur");
        clearOverlays();

        if (magasin_gmap_page == "distributeur") {
            var listeLaterale = document.getElementById("liste_distributeur");
            listeLaterale.innerHTML = "";

            //Building primary_navbar using results marqueurs
            if (marqueurs.length) {
                var primary_navbar;
                var marqueurs_unique = [];

                // Get an unique array of all cities having stores
                for(var i = 0; i < marqueurs.length; i++) {
                    if (!marqueurs_unique.includes(marqueurs[i].getAttribute('nom'))) {
                        marqueurs_unique.push(marqueurs[i].getAttribute('nom'));
                    }
                }

                // This array will be helpful to build the particular navbar to navigate through markers/stores
                if (marqueurs_unique.length) {
                    primary_navbar = "<ul class=\"primary_navbar\" data-navbar=\"location_nav\">";

                    //Loop cities to build navbar items
                    for (var i = 0; i < marqueurs_unique.length; i++) {
                        // Check if marqueurs_unique[i] has 4 seperate words and split it with a <br>
                        if (marqueurs_unique[i].split(' ').length === 4) {
                            let words = marqueurs_unique[i].split(' ');
                            let newText = words[0] + " " + words[1] + "<br>" + words[2] + " " + words[3];

                            primary_navbar +=
                                "<li class=\"navbar_tab" + ((i === 0) ? " active" : "") + "\" data-tab=\"" + marqueurs_unique[i] + "\">" +
                                    '<h2>' + newText + '</h2>' +
                                "</li>";
                        } else {
                            // If the string does not have exactly four words, use it directly without modification
                            primary_navbar +=
                                "<li class=\"navbar_tab" + ((i === 0) ? " active" : "") + "\" data-tab=\"" + marqueurs_unique[i] + "\">" +
                                    '<h2>' + marqueurs_unique[i] + '</h2>' +
                                "</li>";
                        }
                    }
                    primary_navbar += "</ul>";
                } else {
                    primary_navbar = "";
                }
            }
        }

        // S'il la recherche n'aboutit a aucun resultat on met la carte sur la position initiale et on affiche qu'aucun resultat n'a ete trouve
        if (marqueurs.length == 0) {
            displayLoader("show");
            var latlng_default = new google.maps.LatLng(47.9, 1.9);
            maCarte.setCenter(latlng_default);
            maCarte.setZoom(5);
            if (magasin_gmap_page == "distributeur") {
                listeLaterale.innerHTML =
                    '<span class="no_result">' +
                    Translator.translate("txt_no_resultat_shop") +
                    "</span>";
            }

            // Si on a fait une recherche (en appuyant sur le bouton) on affiche la liste
            if (magasin_gmap_page == "distributeur") {
                displayLoader("show");
                $("#nb_magasins").html("");
                document.getElementById("liste_distributeur").innerHTML =
                    '<span class="no_result">' +
                    Translator.translate("txt_no_resultat_shop") +
                    "</span>";
            }
            return;
        }

        // On va creer les marqueurs puis la liste laterale qui est une correspondance des marqueurs suivant la proximite de l'adresse saisie
        var rectangleMarqueurs = new google.maps.LatLngBounds();
        // Récupérer de la date de l'utilisateur pour vérifier si le magasin est encore ouvert
        var date = new Date();
        var time = (date.getHours() < 10 ? "0" : "") + date.getHours();
        time += (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
        for (var i = 0; i < marqueurs.length; i++) {

            var nom = marqueurs[i].getAttribute("nom");
            var adresse = marqueurs[i].getAttribute("adresse");
            var ville = marqueurs[i].getAttribute("ville");
            var cp = marqueurs[i].getAttribute("cp");
            var pays = marqueurs[i].getAttribute("pays");
            if (cp.length == 4 && pays == "France") {
                cp = "0" + cp;
            }

            var id = marqueurs[i].getAttribute("magasin_id");
            var image = marqueurs[i].getAttribute("image");
            //Here we receive a JSON object because all images are visible on store locator, there's no shop_details page
            var image_parse = JSON.parse(image);
            var horaire = marqueurs[i].getAttribute("horaire");

            // Outlet Filter - Checking if existing and non-empty
            var shop_filter = "";
            if (marqueurs[i].getAttribute("filtres").length && (marqueurs[i].getAttribute("filtres").length !== 0)) {
                shop_filter = marqueurs[i].getAttribute("filtres");
            }

            // Rewrite horaire for more style possibilities
            var horaire_arr = horaire.split("<br/>");
            horaire = "";
            $.each(horaire_arr, function(key, value) {
                if(value) {
                    // Split the text by ":"
                    let parts = value.split(':');

                    // Extract the day and time into their own spans
                    let day = "<span>" + parts[0].trim() + "</span>";
                    let time = "<span>" + parts[1].trim() + "</span>";

                    value = "<span class=\"horaire\">" + day + time + "</span>";
                    horaire += value;
                }
            });

            var horaire_open = (horaire_close = 0);
            if (
                marqueurs[i].getAttribute("horaire_open") != null &&
                marqueurs[i].getAttribute("horaire_open") != ""
            ) {
                horaire_open = marqueurs[i]
                    .getAttribute("horaire_open")
                    .replace(/:/g, "");
                horaire_close = marqueurs[i]
                    .getAttribute("horaire_close")
                    .replace(/:/g, "");
            }
            var currently_open = 0;
            if (
                parseInt(horaire_open) < parseInt(time) &&
                parseInt(time) < parseInt(horaire_close)
            ) {
                currently_open = 1;
            }
            var horaire_today = marqueurs[i].getAttribute("horaire_today");
            // Si jamais le magasin est fermé
            if (horaire && !currently_open) {
                horaire_today = close_txt;
            }
            else {
                var horaireOpen = marqueurs[i].getAttribute("horaire_open")?.replace(/:/g, "h");
                var horaireClose = marqueurs[i].getAttribute("horaire_close")?.replace(/:/g, "h");

                if (horaireOpen && horaireClose) {
                    horaire_today = Translator.translate("store_open_until_3", horaireOpen, horaireClose);
                }
            }
            if (marqueurs[i].getAttribute("horaire_plus") != "")
                horaire += "<span class=\"horaire\">" + marqueurs[i].getAttribute("horaire_plus") + "</span>";
            if (marqueurs[i].getAttribute("holiday") != "")
                horaire += "<span class=\"horaire\">" + marqueurs[i].getAttribute("holiday") + "</span>";
            var url = marqueurs[i].getAttribute('url');
            var telephone = marqueurs[i].getAttribute("tel");
            var email = marqueurs[i].getAttribute("email");
            var site = marqueurs[i].getAttribute("site");
            var magasin_type = marqueurs[i].getAttribute("magasin_type");
            var lat = parseFloat(marqueurs[i].getAttribute("lat"));
            var lng = parseFloat(marqueurs[i].getAttribute("lng"));

            if (marqueurs[i].getAttribute("distance"))
                var distance = parseFloat(
                    marqueurs[i].getAttribute("distance")
                );
            var point = new google.maps.LatLng(lat, lng);

            if (primary_navbar !== "") {
                var nav_holder = document.querySelector('.liste_recherche_distributeur .nav_holder');
                nav_holder.innerHTML = primary_navbar;
            }
            var marqueur = creerMarqueur(
                point,
                nom,
                adresse,
                ville,
                cp,
                pays,
                horaire,
                telephone,
                email,
                site,
                image,
                magasin_type,
                i + 1,
                id,
                url
            );

            if (magasin_gmap_page == "distributeur") {
                var nouvelleLigneListe = creerLigneListe(
                    i + 1,
                    id,
                    nom,
                    adresse,
                    ville,
                    cp,
                    pays,
                    currently_open,
                    horaire_today,
                    distance,
                    marqueur,
                    horaire,
                    lat,
                    lng,
                    telephone,
                    image,
                    image_parse,
                    shop_filter
                );
                listeLaterale.appendChild(nouvelleLigneListe);
            }

            markers.push(marqueur);

            rectangleMarqueurs.extend(point);
        }

        // Event sur elem_liste_distributeur
        $(".elem_liste_distributeur").on("mouseover", function() {
            $(this).addClass("hover");
        });

        $(".elem_liste_distributeur").on("mouseout", function() {
            $(this).removeClass("hover");
        });

        // Show store horaires on "store_more" click
        $('.elem_liste_distributeur .store_more').on('click', function(e) {
            var zis = $(this);
            // If user want horaire details, change current shop bullet
            handleCurrentListShop(zis.parent().find('.detail_store'));
            // Display horaires
            displayShopHoraires(zis);
        });

        // Add class actif to show in store list wich marker is actually shown
        $('.elem_liste_distributeur .detail_store').on('click', function(e) {
            var zis = $(this);

            // If horaires displayed, hide them
            if ($('.store_more.opened').length) {
                $('.store_more.opened').removeClass('opened');
                $('.details_dropdown.actif').removeClass('actif').slideUp();
            }
            handleCurrentListShop(zis);
        });

        $('.elem_liste_distributeur').on('click', function(e) {
            var zis = $(this);

            //Display store images on marker/store click
            displayStorePics(zis);
        });

        $('.navbar_tab').click(function () {
            var tab = $(this).data('tab');
            // Identify navbar to handle multiple primary_navbar occurences
            var navbar = $(this).parent().attr('data-navbar');

            /* change nav active on this elem **/
            $(this).parent().find($('.navbar_tab')).removeClass('active');
            $(this).addClass("active");

            /* If text should be present **/
            if (!$('.no_text').length) {
                $(".navbar_cnt[data-navbar='" + navbar +"']").removeClass('actif');
                $(".navbar_cnt[data-nav='" + tab + "'][data-navbar='" + navbar +"']").addClass('actif');
            }

            /* If nav tabs have specific content to show **/
            if ($('.tab_content').length) {
                $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif')
                $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
            }
        });

        $("#nb_magasins").html(
            marqueurs.length + " " + Translator.translate("txt_resultat_boutique")
        );

        if (maCarte) {
            if ($("#retailers_map").length) {

                var mcOptions = {
                    gridSize: 80,
                    textColor: "white",
                    imagePath: path_relative_root + "img/maps/icns/cluster_0",
                };

                clusterer = new MarkerClusterer(maCarte, markers, mcOptions);
                // Pour mettre la position de l'utilisateur
                if (marqueur_user) {
                    marqueur_user.setMap(maCarte);
                }

                markers = [];
            }

            maCarte.setCenter(
                rectangleMarqueurs.getCenter(),
                maCarte.fitBounds(rectangleMarqueurs)
            );

            if (marqueurs.length == 1) {
                maCarte.setZoom(16);
            } else if (magasin_gmap_page == "fiche_produit") {
                maCarte.setZoom(12);
            } else if (marqueur_user) {
                maCarte.setZoom(14);
            }
        }

        //Init navbar first tab and first list element
        nav_holder.querySelector('.navbar_tab').click();
        displayLoader("show");
        OverlayScrollbars(document.querySelector('.liste_distributeur_wrapper'),{});
    });
}

function displayStorePics(zis) {
    // Retrieve store pictures
    var store_pics = zis.find('.store_pics').html();

    //Empty the bloc under the map holding store pictures
    $('.store_loc_left .holder_imgs').empty().html(store_pics);
}

function handleCurrentListShop(bloc) {
    var detail_store = bloc;

    if (!detail_store.hasClass('actif')) {
        $('.detail_store.actif').removeClass('actif');
        detail_store.addClass('actif');
    }
}

function displayShopHoraires(bloc) {
    var details_dropdown = bloc.parent().find('.details_dropdown');

    if (details_dropdown.hasClass('actif')) {
        $('.details_dropdown.actif').removeClass('actif').slideUp();
        $('.store_more.opened').removeClass('opened');
        $('.elem_list_contents').removeClass('opened');
    } else {
        $('.details_dropdown.actif').removeClass('actif').slideUp();
        details_dropdown.addClass('actif').slideDown();
        $('.store_more.opened').removeClass('opened');
        bloc.addClass('opened');
        $('.elem_list_contents').removeClass('opened');
        bloc.parent().addClass('opened');
    }
}

/**
 * Removes the overlays from the map, but keeps them in the array
 */
function clearOverlays() {
    var length_marker = markersArray.length;
    for (var i = 0; i < length_marker; i++) markersArray[i].setMap(null);
    markersArray.length = 0;
}

function downloadUrl(url, rayon, latlng, callback) {

    var param_sup = "&rayon=" + rayon;

    var request = window.ActiveXObject
        ? new ActiveXObject("Microsoft.XMLHTTP")
        : new XMLHttpRequest();

    request.onreadystatechange = function() {
        if (request.readyState == 4) {
            request.onreadystatechange = doNothing;
            var marqueurs = request.responseXML.documentElement.getElementsByTagName(
                "marqueur"
            );

            if (marqueurs.length > 0) {
                callback(request, request.status);
            } else if (rayon == "50km") {
                // aucun resultat pour rayon 50km
                downloadUrl(url, "100km", latlng, callback);

            } else {
                // aucun resultat pour rayon 100km
            }
        }
    };

    request.open("GET", url + param_sup, true);
    request.send(null);
}

function doNothing() {}

/**
 * Creating a marker and its tooltip
 * @param {*} point
 * @param {*} nom
 * @param {*} adresse
 * @param {*} ville
 * @param {*} cp
 * @param {*} pays
 * @param {*} horaire
 * @param {*} telephone
 * @param {*} email
 * @param {*} site
 * @param {*} image_mag
 * @param {*} type_mag
 * @param {*} cpt
 * @param {*} magasin_id
 * @param {*} url
 * @returns
 */
function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url) {
    // Pour ne le charger qu'une seul fois
    if (horaire_txt === null) horaire_txt = Translator.translate("horaires_map");

    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 20 pixels wide by 28 pixels tall.
        new google.maps.Size(20, 28),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/maps/marker_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(22, 40),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
        id: $.trim(magasin_id)
    });

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);

    var cpt_recherche = typeof cpt != "undefined" ? "(" + cpt + ") - " : "";

    var ligneListe = '<div class="info_popup" id="' + magasin_id + '">';

    ligneListe +=
        '<div class="name"><a target="_blank" href="' +
        get_link_google_map_from_address(adresse, cp, ville, pays) +
        '">' +
        nom +
        "</a></div>";

    ligneListe +=
        '<div class="adresse">' +
        adresse +
        "<br />" +
        cp +
        ' <span class="city">' + ville + '</span> ' +
        '<span class="country">' + pays + '</span>' +
        '</div>';

    // Dans la fiche produit on donne la possibilité de choisir le magasin pour la reservation en magasin
    if (magasin_gmap_page == "fiche_produit") {
        ligneListe +=
            '<div class="btn_container"><a href="javascript:void(0)" class="button primary" onclick="chooseStore(' +
            magasin_id +
            ')">Choisir</a></div>';
    }

    ligneListe += "</div>";

    google.maps.event.addListener(marqueur, "click", function() {
        // Datalayer v2
        createStoreDataLayer(magasin_id);

        // Retrieve store id to find matching list_elem store (**)
        var store_id;
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
            store_id = infowindow.anchor.id;
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
            store_id = infowindow.anchor.id;
        }

        // Once the marker is clicked, and its store_id found, make the matching list elem active and adapt primary_navbar's behavior
        if ($('.elem_liste_distributeur[data-store-id="'+ store_id +'"]').length) {
            var list_elem = $('.elem_liste_distributeur[data-store-id="'+ store_id +'"]');
            var elem_nav_tab = list_elem.attr('data-tab');
            var list_detail_store = list_elem.find('.detail_store');

            // Switch primary_navbar's tab to the one containing the chosen list_elem
            $('.primary_navbar .navbar_tab[data-tab="'+ elem_nav_tab +'"]').click();
            handleCurrentListShop(list_detail_store);
        }
    });

    markersArray.push(marqueur);

    return marqueur;
}

/**
 * Creation of a block corresponding to a marker on the lateral list (only in distributeurs.php)
 * @param {*} cpt
 * @param {*} id
 * @param {*} nom
 * @param {*} adresse
 * @param {*} ville
 * @param {*} cp
 * @param {*} pays
 * @param {*} currently_open
 * @param {*} horaire_today
 * @param {*} distance
 * @param {*} marqueur
 * @param {*} horaire
 * @param {*} lat
 * @param {*} lng
 * @param {*} telephone
 * @param {*} image
 * @param {*} image_parse
 * @param {*} shop_filter
 * @returns
 */
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, horaire, lat, lng, telephone, image, image_parse, shop_filter) {
    // Only load it once
    if (horaire_txt === null) horaire_txt = Translator.translate("horaires_map");

    currently_open = parseFloat(currently_open);
    var div = document.createElement("div");

    // If there's more than 0 filter, for the moment it means that the store is outlet (outlet is the only filter enabled for Rodier Stores)
    div.className = "elem_liste_distributeur tab_content " + ((shop_filter.length > 0) ? "with_filter" : "");
    div.dataset.info = id; // Datalayer v2

    // List of attributes to add to work with primary_navbar script
    div.dataset.navbar = "location_nav";
    div.dataset.tab = nom;
    div.dataset.storeId = id;

    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";

    if (lat != "" && lng != "") {
        div.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
        var gmap_link = '<a class="detail_link rodierButton" href="' + get_link_google_map_from_address(adresse,cp,ville,pays) + '" target="_blank"><span>' + t_gotostore + '</span></a>';
    }

    var ligneListe =
        '<div class="elem_list_contents ' + ((shop_filter.length > 0) ? "with_filter" : "") + '" data-filter="' + ((shop_filter.length > 0) ? shop_filter.toLowerCase() : "") + '">' +
        '<div class="nom_store">' +
        "<span>" +
        nom +
        "</span>" +
        "</div>" +
        '<div class="store_more"><span class="more">' + Translator.translate('cookies_texte_link') + '</span><span class="less">' + Translator.translate('close') + '</span></div>' +
        '<div class="content_store">';

    ligneListe += '<div class="detail_store">';

    ligneListe +=
        '<div class="rue_store">' +
        adresse +
        "</div>" +
        '<div class="ville_store">' +
        cp +
        " " +
        ville +
        "</div>" +
        '<div class="pays_store">' +
        pays +
        "</div>";

        if (telephone != '') {
            ligneListe += '<div class="phone_store">' + telephone + '</div>';
        }

        ligneListe += '</div>' + '<div class="details_dropdown">';

        if ((info_horaire_today != '' && currently_open) || (!currently_open && horaire_today)) {
            ligneListe += '<span class="horaire_today"><span class="puce current_horaire horaire ' + class_horaire_today + '">' + horaire_today + '</span> ' + horaire + '</span>';
        }

        if (image_parse != ""){
            ligneListe += '<div class="store_pics">';
            for (image in image_parse) {
                ligneListe += '<div class="wrapper_img"><img src="' + image_parse[image] + '" /></div>';
            }
            ligneListe += '</div>';

        }

        ligneListe += (gmap_link ? gmap_link : "") + '</div>';

            '</div>' +
        '</div>';

    ligneListe += "</div>";

    ligneListe += "</div>" + "</div>";
    div.innerHTML = ligneListe;

    google.maps.event.addDomListener(div, "click", function() {
        google.maps.event.trigger(marqueur, "click");
    });

    return div;
}

function displayLoader(type) {
    if (type == "hide") {
        $target = ".mag_btn_recherche";
        $source = ".mag_btn_loader";
    } else {
        $target = ".mag_btn_loader";
        $source = ".mag_btn_recherche";
    }

    $($target).hide();
    $($source).show();
}

/**
 * Use when submitting store search (search_shop and product sheet (or find in store))
 * @returns
 */
function submitGeoloc() {
    displayLoader("hide");
    geocodeAdresse();

    return false;
}

/**
 * To geolocate the location based on latitude and longitude
 */
function geolocGoto(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);

    // On centre la carte suivant les marqueurs trouves
    maCarte.setCenter(latlng);
    maCarte.setZoom(15);

    $("html, body").animate(
        {
            scrollTop:
                $("#retailers_map").offset().top -
                $("#site_head_wrap").height(),
        },
        500
    );
}

/**
 * Open a window with the route (if location enabled on the browser)
 * @param {*} lat
 * @param {*} lng
 * @returns
 */
function get_link_google_map(lat, lng) {

    if (lng_client !== 0) {

        var pos = {
            lat: lat_client,
            lng: lng_client
        };
        return "https://maps.google.fr/maps?saddr=" + lat_client + "," + lng_client + "&daddr=" + lat + "," + lng;
    } else {
        // non trouvé (lng_client === 0)
        return "https://maps.google.fr/maps?&daddr=" + lat + "," + lng;
    }
}

function get_link_google_map_from_address(address,cp,ville,pays) {
    var fullAddress = address+', '+cp+' '+ville+', '+pays;
    return "https://maps.google.fr/maps/place/" + encodeURIComponent(fullAddress);
}

/**
Toggle product details in cart right column
*/
function toggleListProduct(elem) {
    if($(elem).hasClass("open")) {
        $(elem).removeClass("open");
        $(".total_produit_dropdown").slideUp();
    } else {
        $(elem).addClass("open");
        $(".total_produit_dropdown").slideDown();
    }
}

/**
 * Switch toggle Cart details line position
**/

function switchToggleCartpos() {
    
    if ($(".cart.body_login").length) {
        moveCartDetailsLogin(); //handles login page cart details positionning
    }
    
    if ($(".cart.step_2_0").length) {
        moveCartDetailsNoAccount(); //handles delivery page cart details positionning
    }
    
    if ($(".checkout").length) {
        moveCartDetailsCheckout(); //handles checkout page cart details positionning
    }
    
    // then trigger top_nb_products click when clicking on total amount line
    $(".bill_line.total").on("click", function () {
        $(".top_nb_products").click();
    });
}

function moveCartDetailsNoAccount() {
    // in delivery page without address/account one cannot simply move the whole cart details block (#cart_total)
    // so we move each line separately 
    
    $("#cart_total .recap_cart_title").insertBefore($(".left_side.elem_tunnel")); // recap cart title line
    $(".top_nb_products").insertBefore($(".left_side.elem_tunnel")).css('display', 'flex'); // total products before discount/delivery line
    $(".bill_line.total_produit.toggle").insertBefore($(".left_side.elem_tunnel")); // recap total products before discount/delivery line
    $(".bill_line.discount").insertBefore($(".left_side.elem_tunnel")); // delivery line
    $(".total_produit_dropdown").insertBefore($(".left_side.elem_tunnel")); // product details container
    $('.bill_line.total').insertBefore($(".left_side.elem_tunnel")); // total amount after discount/delivery line

    // tunnel v3 payment step move order promo info if promo code is used
    if($('.bill_line.promo').length && $('.bill_line.used_cp').length) {
        $('.bill_line.used_cp').insertBefore($(".left_side.elem_tunnel")); // code promo used amount line
        $('.bill_line.promo').insertBefore($(".left_side.elem_tunnel")); // code promo used recap line
    }
}

function moveCartDetailsCheckout() {
    // in checkout page one cannot simply move the whole cart details block (#cart_total)
    // so we move each line separately 
    
    $("#cart_total .recap_cart_title").insertBefore($(".bank_left_side")); // recap cart title line
    $(".bill_line.total_produit.toggle").insertBefore($(".bank_left_side")); // recap total products before discount/delivery line
    $('.bill_line.discount').insertBefore($(".bank_left_side")); // delivery line
    $(".total_produit_dropdown").insertBefore($(".bank_left_side")); // product details container
    $(".bill_line.total").insertBefore($(".bank_left_side")); // total amount after discount/delivery line

    // tunnel v3 payment step move order promo info if promo code is used
    if($('.bill_line.promo').length && $('.bill_line.used_cp').length) {
        $('.bill_line.used_cp').insertBefore($(".bank_left_side")); // code promo used amount line
        $('.bill_line.promo').insertBefore($(".bank_left_side")); // code promo used recap line
    }

    if ($(".wrapper_bloc_banque.credit_card.hosted_worldline").length) {
        $(".top:has(#wrapper_payement_secur)").appendTo($(".wrapper_bloc_banque.credit_card.hosted_worldline"));
    }
}

function moveCartDetailsLogin() {
    // in login page we just move the entire cart details block (#cart_total)
    $('.cart.body_login #cart_total').insertBefore('.newlogin_page').css('display', 'flex');
}

function switchAddrRecapCartpos() {
    if ($(".checkout").length) {
        $(".wrapper_payment").insertBefore($(".bank_left_side"));
    } else {
        $("#blocs_address").insertBefore(".left_side.elem_tunnel");
    }
}

$(window).on("basketToWish_complete", function () {

    getWishlistProducts();
});
